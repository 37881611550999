import React from 'react';

// Components imported in the screen
import GameHeader from '../../../ResultsSection/GameHeader/GameHeader';

// Styled components
import {
     EventPlayerName,
     EventPlayerSubstituted,
     FlexOne,
     HomeTeamEvent,
     LineupContainer,
     VisitorTeamEvent
} from '../../../../styles';

// Icons
import CloseIcon from '@mui/icons-material/Close';
import SquareIcon from '@mui/icons-material/Square';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SyncIcon from '@mui/icons-material/Sync';
import ErrorIcon from '@mui/icons-material/Error';

const Event = (props) => {
     const events = props.events;
     const localteamid = props.localteamid;
     const visitorteamid = props.visitorteamid;
     const type = props.type;
     const scores = props.scores;
     const status = props.status;

     return (
          <>
               {
                    type === 1 &&
                    (scores.ht_score || status === 'LIVE') &&
                    <GameHeader
                         event='Primeiro tempo'
                         score={
                              status === 'LIVE' ?
                                   scores.ht_score ?
                                        `${scores.ht_score.split('-')[0]} — ${scores.ht_score.split('-')[1]}` :
                                        '0 — 0' :
                                   `${scores.ht_score.split('-')[0]} — ${scores.ht_score.split('-')[1]}`
                         }
                    />
               }
               {
                    type === 2 &&
                    (scores.ft_score || status === 'LIVE') &&
                    <GameHeader
                         event='Segundo tempo'
                         score={
                              status === 'LIVE' ?
                                   scores.ft_score ?
                                        `${scores.ft_score.split('-')[0]} — ${scores.ft_score.split('-')[1]}` :
                                        '0 — 0' :
                                   `${scores.ft_score.split('-')[0]} — ${scores.ft_score.split('-')[1]}`
                         }
                    />
               }
               {
                    type === 3 &&
                    scores.et_score &&
                    <GameHeader
                         event='Prorrogação'
                         score={
                              scores.et_score ?
                                   `${scores.et_score.split('-')[0]} — ${scores.et_score.split('-')[1]}` :
                                   '0 — 0'
                         }
                    />
               }
               {
                    type === 4 &&
                    scores.ps_score &&
                    <GameHeader
                         event='Pênaltis'
                         score={
                              scores.ps_score ?
                                   `${scores.ps_score.split('-')[0]} — ${scores.ps_score.split('-')[1]}` :
                                   '0 — 0'
                         }
                    />
               }
               <LineupContainer>
                    <FlexOne>
                         {
                              type === 1 && events.length > 0 && events?.map((element) => {
                                   if (element.minute <= 45 && element.type !== 'pen_shootout_miss' && element.type !== 'pen_shootout_goal' && Number(element.team_id) === localteamid) {
                                        return <HomeTeamEvent key={element.id}>
                                             {`${element.minute}'`}
                                             {element.type === 'missed_penalty' && <CloseIcon sx={{ width: 15 }} />}
                                             {element.type === 'goal' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'goal' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'penalty' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'penalty' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'yellowcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#FFCD00' }} />}
                                             {element.type === 'redcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'yellowred' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'substitution' && <SyncIcon sx={{ width: 15 }} style={{ color: '#2178BD' }} />}
                                             <EventPlayerName href={`/player/${element.player_id}`} target='_blank'>{element.player_name}</EventPlayerName>
                                             {element.type === 'missed_penalty' && <span style={{ fontWeight: 400 }}>(Pênalti perdido)</span>}
                                             {element.type === 'penalty' && <span style={{ fontWeight: 400 }}>(Gol de pênalti)</span>}
                                             {element.type === 'substitution' && <EventPlayerSubstituted href={`/player/${element.related_player_id}`} target='_blank'>{element.related_player_name}</EventPlayerSubstituted>}
                                        </HomeTeamEvent>
                                   } else if (element.minute <= 45 && element.type !== 'pen_shootout_miss' && element.type !== 'pen_shootout_goal' && Number(element.team_id) === visitorteamid) {
                                        return <VisitorTeamEvent key={element.id}>
                                             {element.type === 'missed_penalty' && <span style={{ fontWeight: 400 }}>(Pênalti perdido)</span>}
                                             {element.type === 'penalty' && <span style={{ fontWeight: 400 }}>(Gol de pênalti)</span>}
                                             {element.type === 'substitution' && <EventPlayerSubstituted href={`/player/${element.related_player_id}`} target='_blank'>{element.related_player_name}</EventPlayerSubstituted>}
                                             <EventPlayerName href={`/player/${element.player_id}`} target='_blank'>{element.player_name}</EventPlayerName>
                                             {element.type === 'missed_penalty' && <CloseIcon sx={{ width: 15 }} />}
                                             {element.type === 'goal' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'goal' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'penalty' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'penalty' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'yellowcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#FFCD00' }} />}
                                             {element.type === 'redcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'yellowred' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'substitution' && <SyncIcon sx={{ width: 15 }} style={{ color: '#2178BD' }} />}
                                             {`${element.minute}'`}
                                        </VisitorTeamEvent>
                                   } else {
                                        return null;
                                   }
                              })
                         }
                         {
                              type === 2 && events.length > 0 && events?.map((element) => {
                                   if (element.minute > 45 && element.minute <= 90 && Number(element.team_id) === localteamid) {
                                        return <HomeTeamEvent key={element.id}>
                                             {`${element.minute}'`}
                                             {element.type === 'goal' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'goal' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'penalty' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'penalty' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'yellowcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#FFCD00' }} />}
                                             {element.type === 'redcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'yellowred' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'substitution' && <SyncIcon sx={{ width: 15 }} style={{ color: '#2178BD' }} />}
                                             <EventPlayerName href={`/player/${element.player_id}`} target='_blank'>{element.player_name}</EventPlayerName>
                                             {element.type === 'missed_penalty' && <span style={{ fontWeight: 400 }}>(Pênalti perdido)</span>}
                                             {element.type === 'penalty' && <span style={{ fontWeight: 400 }}>(Gol de pênalti)</span>}
                                             {element.type === 'substitution' && <EventPlayerSubstituted href={`/player/${element.related_player_id}`} target='_blank'>{element.related_player_name}</EventPlayerSubstituted>}
                                        </HomeTeamEvent>
                                   } else if (element.minute > 45 && element.minute <= 90 && Number(element.team_id) === visitorteamid) {
                                        return <VisitorTeamEvent key={element.id}>
                                             {element.type === 'missed_penalty' && <span style={{ fontWeight: 400 }}>(Pênalti perdido)</span>}
                                             {element.type === 'penalty' && <span style={{ fontWeight: 400 }}>(Gol de pênalti)</span>}
                                             {element.type === 'substitution' && <EventPlayerSubstituted href={`/player/${element.related_player_id}`} target='_blank'>{element.related_player_name}</EventPlayerSubstituted>}
                                             <EventPlayerName href={`/player/${element.player_id}`} target='_blank'>{element.player_name}</EventPlayerName>
                                             {element.type === 'goal' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'goal' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'penalty' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'penalty' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'yellowcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#FFCD00' }} />}
                                             {element.type === 'redcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'yellowred' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'substitution' && <SyncIcon sx={{ width: 15 }} style={{ color: '#2178BD' }} />}
                                             {`${element.minute}'`}
                                        </VisitorTeamEvent>
                                   } else {
                                        return null;
                                   }
                              })
                         }
                         {
                              type === 3 && events.length > 0 && events?.map((element) => {
                                   if (element.minute > 90 && element.minute <= 120 && Number(element.team_id) === localteamid) {
                                        return <HomeTeamEvent key={element.id}>
                                             {`${element.minute}'`}
                                             {element.type === 'goal' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'goal' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'penalty' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'penalty' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'yellowcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#FFCD00' }} />}
                                             {element.type === 'redcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'yellowred' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'substitution' && <SyncIcon sx={{ width: 15 }} style={{ color: '#2178BD' }} />}
                                             <EventPlayerName href={`/player/${element.player_id}`} target='_blank'>{element.player_name}</EventPlayerName>
                                             {element.type === 'missed_penalty' && <span style={{ fontWeight: 400 }}>(Pênalti perdido)</span>}
                                             {element.type === 'penalty' && <span style={{ fontWeight: 400 }}>(Gol de pênalti)</span>}
                                             {element.type === 'substitution' && <EventPlayerSubstituted href={`/player/${element.related_player_id}`} target='_blank'>{element.related_player_name}</EventPlayerSubstituted>}
                                        </HomeTeamEvent>
                                   } else if (element.minute > 90 && element.minute <= 120 && Number(element.team_id) === visitorteamid) {
                                        return <VisitorTeamEvent key={element.id}>
                                             {element.type === 'missed_penalty' && <span style={{ fontWeight: 400 }}>(Pênalti perdido)</span>}
                                             {element.type === 'penalty' && <span style={{ fontWeight: 400 }}>(Gol de pênalti)</span>}
                                             {element.type === 'substitution' && <EventPlayerSubstituted href={`/player/${element.related_player_id}`} target='_blank'>{element.related_player_name}</EventPlayerSubstituted>}
                                             <EventPlayerName href={`/player/${element.player_id}`} target='_blank'>{element.player_name}</EventPlayerName>
                                             {element.type === 'goal' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'goal' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'penalty' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'penalty' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'yellowcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#FFCD00' }} />}
                                             {element.type === 'redcard' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'yellowred' && <SquareIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'substitution' && <SyncIcon sx={{ width: 15 }} style={{ color: '#2178BD' }} />}
                                             {`${element.minute}'`}
                                        </VisitorTeamEvent>
                                   } else {
                                        return null;
                                   }
                              })
                         }
                         {
                              type === 4 && events.length > 0 && events?.map((element) => {
                                   if (element.minute <= 45 && (element.type === 'pen_shootout_miss' || element.type === 'pen_shootout_goal') && Number(element.team_id) === localteamid) {
                                        return <HomeTeamEvent key={element.id}>
                                             {`${element.minute}`}
                                             {element.type === 'pen_shootout_miss' && <ErrorIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'pen_shootout_goal' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {element.type === 'pen_shootout_goal' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             <EventPlayerName href={`/player/${element.player_id}`} target='_blank'>{element.player_name}</EventPlayerName>
                                             {element.type === 'pen_shootout_goal' && <EventPlayerSubstituted>(Gol de pênalti)</EventPlayerSubstituted>}
                                             {element.type === 'pen_shootout_miss' && <EventPlayerSubstituted>(Pênalti perdido)</EventPlayerSubstituted>}
                                        </HomeTeamEvent>
                                   } else if (element.minute <= 45 && (element.type === 'pen_shootout_miss' || element.type === 'pen_shootout_goal') && Number(element.team_id) === visitorteamid) {
                                        return <VisitorTeamEvent key={element.id}>
                                             {element.type === 'pen_shootout_goal' && <EventPlayerSubstituted>(Gol de pênalti)</EventPlayerSubstituted>}
                                             {element.type === 'pen_shootout_miss' && <EventPlayerSubstituted>(Pênalti perdido)</EventPlayerSubstituted>}
                                             <EventPlayerName href={`/player/${element.player_id}`} target='_blank'>{element.player_name}</EventPlayerName>
                                             {element.type === 'pen_shootout_miss' && <ErrorIcon sx={{ width: 15 }} style={{ color: '#DC0000' }} />}
                                             {element.type === 'pen_shootout_goal' && `${element.result?.split('-')[0]} - ${element.result?.split('-')[1]}`}
                                             {element.type === 'pen_shootout_goal' && <SportsSoccerIcon sx={{ width: 15 }} />}
                                             {`${element.minute}`}
                                        </VisitorTeamEvent>
                                   } else {
                                        return null;
                                   }
                              })
                         }
                    </FlexOne>
               </LineupContainer>
          </>
     );
};

export default Event;