import React from 'react';
import { NoDataFullPageContainer, NoDataMainDiv, NoDataSpan } from '../../styles';
import ReactLoading from 'react-loading';

// Components imported in the screen
import AllGames from './Categories/AllGames';
import FinishedGames from './Categories/FinishedGames';
import LiveGames from './Categories/LiveGames';
import ScheduledGames from './Categories/ScheduledGames';

const ResultsSection = (props) => {
     const sport = props.sport;
     const option = props.option;
     const date = props.date;
     const inputText = props.inputText;

     return (
          <>
               {
                    sport === 'soccer' ?
                         option === 'all' ?
                              <AllGames
                                   date={date}
                                   inputText={inputText}
                              /> :
                              option === 'live' ?
                                   <LiveGames
                                        date={date}
                                        inputText={inputText}
                                   /> :
                                   option === 'finished' ?
                                        <FinishedGames
                                             date={date}
                                             inputText={inputText}
                                        /> :
                                        option === 'scheduled' ?
                                             <ScheduledGames
                                                  date={date}
                                                  inputText={inputText}
                                             /> :
                                             <></>
                         : <NoDataFullPageContainer>
                              <NoDataMainDiv>
                                   <NoDataSpan>
                                        <ReactLoading
                                             type='spin'
                                             color='#2178BD'
                                             height={22}
                                             width={22}
                                        />
                                        Em breve.
                                   </NoDataSpan>
                              </NoDataMainDiv>
                         </NoDataFullPageContainer>
               }
          </>
     );
};

export default ResultsSection;