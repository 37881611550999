import React from 'react';

// Components imported in the screen
import GameHeader from '../../../ResultsSection/GameHeader/GameHeader';
import GameData from '../../../ResultsSection/GameData/GameData';

import { setGameStatus } from '../../../../functions';
import { MoreMatchesContainer, MoreMatchesLink } from '../../../../styles';

const LastMatches = (props) => {
     const team = props.team;
     const latestMatches = props.latestMatches;

     return (
          <>
               <GameHeader
                    event='Últimas 10 partidas'
               />
               {
                    latestMatches.map((game, index) => {
                         const status = setGameStatus(game.status);
                         return <GameData
                              key={index}
                              id={game.match_id}
                              time={game.date}
                              logoTeamA={game.local_flag}
                              teamA={game.local_name}
                              logoTeamB={game.rival_flag}
                              teamB={game.rival_name}
                              scoreA={game.local_score}
                              scoreB={game.rival_score}
                              status={status}
                              scorePenaltyA={''}
                              scorePenaltyB={''}
                              gameResult={game.result}
                         />
                    })
               }
               <MoreMatchesContainer>
                    <MoreMatchesLink
                         href={`/team/${team.id}/lastmatches`}
                         target='_blank'
                    >
                         Ver mais partidas
                    </MoreMatchesLink>
               </MoreMatchesContainer>
          </>
     );
};

export default LastMatches;