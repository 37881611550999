import React from 'react';

// Styled components
import {
     ActiveNavigationBarListItem,
     NavigationBarContainer,
     NavigationBarList,
     NavigationBarListItem
} from '../../styles';

// Icons
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import SportsFootballIcon from '@mui/icons-material/SportsFootball';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import SportsVolleyballIcon from '@mui/icons-material/SportsVolleyball';

const navigationItems = [
     {
          icon: <SportsSoccerIcon sx={{ fontSize: 22 }} />,
          name: 'Futebol',
          value: 'soccer'
     },
     {
          icon: <SportsBasketballIcon sx={{ fontSize: 22 }} />,
          name: 'Basquete',
          value: 'basketball'
     },
     {
          icon: <SportsMmaIcon sx={{ fontSize: 22 }} />,
          name: 'MMA',
          value: 'mma'
     },
     {
          icon: <SportsTennisIcon sx={{ fontSize: 22 }} />,
          name: 'Tênis',
          value: 'tennis'
     },
     {
          icon: <SportsFootballIcon sx={{ fontSize: 22 }} />,
          name: 'Futebol Americano',
          value: 'football'
     },
     {
          icon: <SportsVolleyballIcon sx={{ fontSize: 22 }} />,
          name: 'Vôlei',
          value: 'volleyball'
     },
]

const NavigationBar = (props) => {
     const sport = props.sport;

     return (
          <NavigationBarContainer>
               <NavigationBarList>
                    {
                         navigationItems.map((item, index) => {
                              if (sport === item.value) {
                                   return <ActiveNavigationBarListItem
                                        key={index}
                                        onClick={() => props.handleNavigationBar(item.value)}
                                   >
                                        {item.icon}
                                        {item.name}
                                   </ActiveNavigationBarListItem>
                              } else {
                                   return <NavigationBarListItem
                                        key={index}
                                        onClick={() => props.handleNavigationBar(item.value)}
                                   >
                                        {item.icon}
                                        {item.name}
                                   </NavigationBarListItem>
                              }
                         })
                    }
               </NavigationBarList>
          </NavigationBarContainer>
     );
};

export default NavigationBar;