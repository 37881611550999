import React from 'react';

// Components imported in the screen
import GameHeader from '../../../ResultsSection/GameHeader/GameHeader';

// Styled components
import {
     LineupContainer,
     MatchDetailsIconAndTitle,
     MatchDetailsList,
     MatchDetailsListItem,
     SpaceBetween
} from '../../../../styles';

const PlayerDetails = (props) => {
     const player = props.player;
     const age = props.age;

     return (
          <>
               <GameHeader
                    event='Detalhes do jogador'
               />
               <LineupContainer>
                    <MatchDetailsList>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        Idade
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {age} anos
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        Data de nascimento
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {player?.birthdate}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        Altura
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {player?.height || 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        Peso
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {player?.weight || 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        Local de nascimento
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {player?.birthplace || 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        País
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {player?.country?.data?.name || 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                    </MatchDetailsList>
               </LineupContainer>
          </>
     );
};

export default PlayerDetails;