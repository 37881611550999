export const setGameStatus = (value) => {
     switch (value) {
          case 'LIVE':
               return 'Ao vivo';
          case 'HT':
               return 'Intervalo';
          case 'FT':
               return 'Finalizado';
          case 'ET':
               return 'Prorrogação';
          case 'PEN_LIVE':
               return 'Pênaltis';
          case 'AET':
               return 'Finalizado na prorrogação';
          case 'BREAK':
               return 'Fim do tempo regular';
          case 'FT_PEN':
               return 'Finalizado nos pênaltis';
          case 'CANCL':
               return 'Cancelado';
          case 'POSTP':
               return 'Adiado';
          case 'INT':
               return 'Interrompido';
          case 'ABAN':
               return 'Abandonado';
          case 'SUSP':
               return 'Suspenso';
          case 'TBA':
               return 'A ser definido';
          case 'AWARDED':
               return 'Vencedor a ser decidido';
          case 'DELAYED':
               return 'Atrasado';
          case 'WO':
               return 'Vitória por WO';
          case 'AU':
               return 'Aguardando atualizações';
          case 'DELETED':
               return 'Excluído';
          default:
               return 'Não iniciado';
     };
};

export const setPlayerPosition = (value) => {
     if (value === 'Goalkeeper' || value === 1) {
          return 'Goleiro';
     } else if (value === 'Defender' || value === 2) {
          return 'Zagueiro';
     } else if (value === 'Midfielder' || value === 3) {
          return 'Meio-campo';
     } else if (value === 'Attacker' || value === 4) {
          return 'Atacante';
     };
};

export const setAge = (value) => {
     if (value === undefined || value === null) return;
     const formatedAge = `${value.split('/')[2]}/${value.split('/')[1]}/${value.split('/')[0]}`;
     const today = new Date();
     const birthDate = new Date(formatedAge);
     let age = today.getFullYear() - birthDate.getFullYear();
     const month = today.getMonth() - birthDate.getMonth();
     if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
          age--;
     }
     return age;
};

export const setTransferStatus = (type, transfer) => {
     switch (type) {
          case 'IN':
               switch (transfer) {
                    case 'loan':
                         return 'Devolução';
                    case 'bought':
                         return 'Comprado';
                    case 'free':
                         return 'Agente livre';
                    default:
                         return '—';
               }
          default:
               switch (transfer) {
                    case 'loan':
                         return 'Empréstimo';
                    case 'bought':
                         return 'Vendido';
                    case 'free':
                         return 'Agente livre';
                    default:
                         return '—';
               }
     };
};