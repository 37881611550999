import React from 'react';

// Styled components
import {
     ActiveNavigationBarListItem,
     NavigationBarContainer,
     NavigationBarList,
     NavigationBarListItem
} from '../../../../styles';

const navigationItems = [
     {
          name: 'Detalhes do time',
          value: 'details'
     },
     {
          name: 'Equipe',
          value: 'squad'
     },
     // {
     //      name: 'Transferências',
     //      value: 'transfers'
     // },
     {
          name: 'Últimas partidas',
          value: 'last'
     },
     {
          name: 'Próximas partidas',
          value: 'next'
     },
];

const Navigation = (props) => {
     const option = props.option;

     return (
          <>
               <NavigationBarContainer>
                    <NavigationBarList>
                         {
                              navigationItems.map((item, index) => {
                                   if (option === item.value) {
                                        return <ActiveNavigationBarListItem
                                             key={index}
                                             onClick={() => props.handleTeamOption(item.value)}
                                        >
                                             {item.name}
                                        </ActiveNavigationBarListItem>
                                   } else {
                                        return <NavigationBarListItem
                                             key={index}
                                             onClick={() => props.handleTeamOption(item.value)}
                                        >
                                             {item.name}
                                        </NavigationBarListItem>
                                   }
                              })
                         }
                    </NavigationBarList>
               </NavigationBarContainer>
          </>
     );
};

export default Navigation;