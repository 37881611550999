import React from 'react';

// Logo
import logo from '../../whitelogo.png';

// Styled components
import {
     HeaderA,
     HeaderContainer,
     Logo
} from '../../styles';

const Header = () => {
     return (
          <HeaderContainer>
               <HeaderA
                    href='/'
               >
                    <Logo
                         src={logo}
                         alt='HunterScores'
                    />
               </HeaderA>
          </HeaderContainer>
     );
};

export default Header;