import React from 'react';

// Styled components
import {
     Blink,
     GameDataContainer,
     GameDataLeft,
     GameDataLeftHour,
     GameDataLeftTeamA,
     GameDataLeftTeamALogo,
     GameDataLeftTeamB,
     GameDataLeftTeamBLogo,
     GameDataLeftTeams,
     GameDataResult,
     GameDataRight,
     GameDataRightPenaltyValue,
     GameDataRightScore,
     GameDataRightScoreValue,
     SpaceBetween
} from '../../../styles';

const GameData = (props) => {
     const id = props.id;

     return (
          <GameDataContainer
               href={`/match/${id}`}
               target='_blank'
               title='Clique para ver os detalhes da partida'
          >
               <GameDataLeft>
                    <GameDataLeftHour>
                         {
                              props.status === 'Cancelado' ?
                                   <span style={{ fontWeight: 'bold', color: 'red' }}>{props.status}</span> :
                                   <span>{props.status}</span>
                         }
                         <span style={{
                              color: props.status === 'Ao vivo' ? '#FF0046' : '',
                              fontWeight: props.status === 'Ao vivo' ? 'bold' : ''
                         }}>
                              {
                                   props.status === 'Ao vivo'
                                        ? props.added
                                             ?
                                             <>
                                                  {props.time}<Blink>'</Blink> + {props.added}
                                             </>
                                             :
                                             <>
                                                  {props.time}<Blink>'</Blink>
                                             </>
                                        : `${props.time}`
                              }
                         </span>
                    </GameDataLeftHour>
                    <GameDataLeftTeams>
                         <GameDataLeftTeamA>
                              <GameDataLeftTeamALogo
                                   src={props.logoTeamA}
                                   onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "https://cdn.sportmonks.com/images/soccer/team_placeholder.png";
                                   }}
                                   alt={props.teamA}
                              />
                              {
                                   props.scoreA > props.scoreB && props.status === 'Finalizado' ?
                                        <span style={{ fontWeight: 'bold' }}>{props.teamA}</span> :
                                        <span>{props.teamA}</span>
                              }
                         </GameDataLeftTeamA>
                         <GameDataLeftTeamB>
                              <GameDataLeftTeamBLogo
                                   src={props.logoTeamB}
                                   onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = "https://cdn.sportmonks.com/images/soccer/team_placeholder.png";
                                   }}
                                   alt={props.teamB}
                              />
                              {
                                   props.scoreB > props.scoreA && props.status === 'Finalizado' ?
                                        <span style={{ fontWeight: 'bold' }}>{props.teamB}</span> :
                                        <span>{props.teamB}</span>
                              }
                         </GameDataLeftTeamB>
                    </GameDataLeftTeams>
               </GameDataLeft>
               <GameDataRight>
                    <GameDataRightScore>
                         <SpaceBetween>
                              <GameDataRightScoreValue>
                                   {
                                        props.scoreA > props.scoreB && (props.status === 'Finalizado' || props.status === 'Finalizado na prorrogação') ?
                                             <span style={{ fontWeight: 'bold' }}>{props.scoreA}</span> :
                                             <span>{props.scoreA}</span>
                                   }
                              </GameDataRightScoreValue>
                              <GameDataRightPenaltyValue>{props.scorePenaltyA !== '' ? `(${props.scorePenaltyA})` : ''}</GameDataRightPenaltyValue>
                         </SpaceBetween>
                         <SpaceBetween>
                              <GameDataRightScoreValue>
                                   {
                                        props.scoreB > props.scoreA && (props.status === 'Finalizado' || props.status === 'Finalizado na prorrogação') ?
                                             <span style={{ fontWeight: 'bold' }}>{props.scoreB}</span> :
                                             <span>{props.scoreB}</span>
                                   }
                              </GameDataRightScoreValue>
                              <GameDataRightPenaltyValue>
                                   {
                                        props.scorePenaltyB > props.scorePenaltyA && props.status === 'Finalizado nos pênaltis' ?
                                             <span style={{ fontWeight: 'bold' }}>{props.scorePenaltyB !== '' ? `(${props.scorePenaltyB})` : ''}</span> :
                                             <span>{props.scorePenaltyB !== '' ? `(${props.scorePenaltyB})` : ''}</span>
                                   }
                              </GameDataRightPenaltyValue>
                         </SpaceBetween>
                    </GameDataRightScore>
                    <SpaceBetween>
                         {
                              props.gameResult &&
                              <GameDataResult gameResult={props.gameResult}>
                                   {props.gameResult}
                              </GameDataResult>
                         }
                    </SpaceBetween>
               </GameDataRight>
          </GameDataContainer>
     );
};

export default GameData;