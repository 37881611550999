import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

// Components imported in the screen
import MatchData from './MatchData/MatchData';
import MatchHeader from './MatchHeader/MatchHeader';

// Styled components
import {
     Container, NoDataFullPageContainer, NoDataMainDiv, NoDataSpan
} from '../../styles';

// Icons
import ErrorIcon from '@mui/icons-material/Error';

const MatchDetailsSection = () => {
     const { id } = useParams();

     const [stats, setStats] = useState([]);

     useEffect(() => {
          async function getStats() {
               const response = await axios
                    .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/fixtures/${id}?
                         api_token=${process.env.REACT_APP_API_TOKEN}
                         &include=events,venue,stats,bench,goals,cards,referee,league,localTeam,visitorTeam,lineup
                         &tz=America/Fortaleza`);
               setStats(response.data.data);
          };
          if (id !== 'null') getStats();
     }, [id]);

     if (id === 'null') return (
          <NoDataFullPageContainer>
               <NoDataMainDiv>
                    <NoDataSpan>
                         <ErrorIcon
                              sx={{
                                   fontSize: 22,
                                   color: '#2178BD'
                              }}
                         />
                         Não há dados para a partida selecionada.
                    </NoDataSpan>
               </NoDataMainDiv>
          </NoDataFullPageContainer>
     );

     if (stats.length === 0) return null;

     return (
          <Container>
               <MatchHeader
                    league={stats?.league?.data.name}
                    leagueflag={stats?.league?.data.logo_path}
                    date={stats?.time?.starting_at?.date}
                    hour={stats?.time?.starting_at?.time.slice(0, 5)}
                    scores={stats?.scores}
                    status={stats?.time?.status}
                    minute={stats?.time?.minute}
                    added={stats?.time?.injury_time}
                    localteamflag={stats?.localTeam?.data?.logo_path}
                    localteamname={stats?.localTeam?.data?.name}
                    localteamid={stats?.localTeam?.data?.id}
                    visitorteamflag={stats?.visitorTeam?.data?.logo_path}
                    visitorteamname={stats?.visitorTeam?.data?.name}
                    visitorteamid={stats?.visitorTeam?.data?.id}
               />
               <MatchData
                    events={stats?.events?.data}
                    lineup={stats?.lineup?.data}
                    bench={stats?.bench?.data}
                    stats={stats?.stats?.data}
                    localteamid={stats?.localTeam?.data?.id}
                    visitorteamid={stats?.visitorTeam?.data?.id}
                    localteamflag={stats?.localTeam?.data?.logo_path}
                    visitorteamflag={stats?.visitorTeam?.data?.logo_path}
                    venue={stats?.venue?.data}
                    referee={stats?.referee?.data}
                    attendance={stats?.attendance}
                    scores={stats?.scores}
                    status={stats?.time?.status}
               />
          </Container>
     );
};

export default MatchDetailsSection;