import React from 'react';

// Styled components
import {
     CountryAndLeagueHeader,
     CountryAndLeagueHeaderLogo,
     EventPlayerName,
     Picture,
     PictureContainer,
     TeamDetailsContainer,
     TeamDetailsMainDiv,
     TeamDetailsSecondaryDiv,
     TeamDetailsTeamName,
     TeamDetailsTeamNameContainer,
     TeamDetailsTwitterContainer
} from '../../../styles';

// Icons
import TwitterIcon from '@mui/icons-material/Twitter';

const TeamHeader = (props) => {
     const team = props.team;

     return (
          <TeamDetailsContainer>
               <TeamDetailsMainDiv>
                    <CountryAndLeagueHeader>
                         <CountryAndLeagueHeaderLogo
                              src={team?.country?.data?.image_path}
                              alt=''
                         />
                         {team?.country?.data?.name}
                    </CountryAndLeagueHeader>
                    <TeamDetailsSecondaryDiv>
                         <PictureContainer>
                              <Picture
                                   src={team?.logo_path || 'https://cdn.sportmonks.com/images/soccer/team_placeholder.png'}
                                   alt=''
                              />
                         </PictureContainer>
                         <div>
                              <TeamDetailsTeamNameContainer>
                                   <TeamDetailsTeamName>
                                        {team?.name}
                                   </TeamDetailsTeamName>
                                   {
                                        team.short_code &&
                                        `(${team.short_code})`
                                   }
                              </TeamDetailsTeamNameContainer>
                              {
                                   team?.twitter &&
                                   <TeamDetailsTwitterContainer>
                                        <TwitterIcon
                                             sx={{ color: '#2178BD', fontSize: 20 }}
                                        />
                                        <EventPlayerName
                                             href={`https://twitter.com/${team.twitter}`}
                                             target='_blank'
                                        >
                                             {team.twitter}
                                        </EventPlayerName>
                                   </TeamDetailsTwitterContainer>
                              }
                         </div>
                    </TeamDetailsSecondaryDiv>
               </TeamDetailsMainDiv>
          </TeamDetailsContainer>
     );
};

export default TeamHeader;