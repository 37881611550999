import styled from "styled-components";

// GENERAL STYLES
export const DisplayFlexAndFlexOne = styled.div`
     flex: 1;
     display: flex;
`;

export const FlexOne = styled.div`
     flex: 1;
`;

export const Font12 = styled.span`
     font-size: 12px;
`;

export const BoldFont = styled.div`
     font-weight: bold;
`;

export const Blink = styled.span`
     @keyframes blinker {
          50% {
               opacity: 0;
          }
     }

	animation: blinker 1.5s linear infinite;
     color: red;
     font-family: sans-serif;
`;

export const MainContainer = styled.div`
     min-height: calc(100vh - 340px);
     display: flex;
     flex-direction: column;

     @media (max-width: 425px) {
          min-height: calc(100vh - 590px);
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          min-height: calc(100vh - 520px);
     }
`;

export const LoadingContainer = styled.div`
     flex: 1;
     display: flex;
     justify-content: center;
     align-items: center;
     margin-top: ${props => props.transfers ? '20px' : 0}
`;

export const SpaceBetween = styled.div`
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 0 10px;
`;

export const PictureContainer = styled.a`
     display: flex;
     width: 80px;
     height: 80px;
     border: 1px solid #C8CDCD;
     border-radius: 10px;
     justify-content: center;
     align-items: center;
     padding: 10px;
     background: #FFFFFF;

     &:hover {
          cursor: pointer;
     }
`;

export const Picture = styled.img`
     width: 75px;
     height: auto;
     max-height: 75px;
`;

export const CountryAndLeagueHeader = styled.div`
     margin-bottom: 20px;
     display: flex;
     gap: 10px;
     align-items: center;
`;

export const CountryAndLeagueHeaderLogo = styled.img`
     width: 25px;
     margin-bottom: 2px;
`;

// CONTAINER
export const Container = styled.div`
     height: 100%;
     width: 100%;
`;

// HEADER SECTION
export const HeaderContainer = styled.div`
     width: 100%;
     height: 90px;
     background: #2178BD;
     display: flex;
     justify-content: center;
     align-items: center;
`;

export const HeaderA = styled.a`
     height: 40%;
`;

export const Logo = styled.img`
     height: 100%;
     cursor: pointer;
`;

// NAVIGATION BAR SECTION
export const NavigationBarContainer = styled.div`
     width: 100%;
     height: 60px;
     display: flex;
     align-items: center;
     border-bottom: 1px solid #C8CDCD;
`;

export const NavigationBarList = styled.ul`
     width: 50%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 0;
     margin: 0 auto;

     @media (max-width: 425px) {
          width: 100%;
          overflow: auto;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
          overflow: auto;
     }
`;

export const NavigationBarListItem = styled.li`
     height: 57px;
     list-style: none;
     cursor: pointer;
     display: flex;
     align-items: center;
     gap: 10px;
     padding: 0px 10px;
     border-bottom: 3px solid transparent;

     &:hover {
          border-bottom: 3px solid #2178BD;
     }

     @media (max-width: 425px) {
          font-size: 14px;
          text-align: center;
     }
`;

export const ActiveNavigationBarListItem = styled.li`
     height: 60px;
     list-style: none;
     cursor: pointer;
     display: flex;
     align-items: center;
     gap: 10px;
     padding: 0px 10px;
     background: #DDDDDD;

     @media (max-width: 425px) {
          font-size: 14px;
          text-align: center;
     }
`;

// SEARCH OPTIONS SECTION
export const SearchBarContainer = styled.div`
     width: 50%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 0;
     margin: 0 auto;

     @media (max-width: 425px) {
          width: 100%;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
     }
`;

export const SearchBarLeftSide = styled.div`
     display: flex;
     gap: 20px;

     @media (max-width: 425px) {
          width: 100%;
          gap: 10px;
     }
`;

export const SearchBarDateButton = styled.div`
     width: 30px;
     height: 30px;
     cursor: pointer;
     display: flex;
     align-items: center;
     justify-content: center;
     background: #FFFFFF;
`;

export const SearchBarInput = styled.input`
     height: 20px;
     border: 0;
     outline: 0;
     padding: 5px;
     width: 200px;
     text-align: 'center';
     font-family: 'Exo', sans-serif;

     @media (max-width: 425px) {
          width: 100px;
          font-size: 12px;
     }
`;


// GAME HEADER SECTION
export const GameHeaderContainer = styled.div`
     width: 50%;
     height: 50px;
     margin: 0 auto;
     background: #2178BD;
     color: #FFFFFF;
     border-radius: 0 0 15px 15px;
     display: flex;

     @media (max-width: 425px) {
          width: 100%;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
     }
`;

export const GameHeaderMainDiv = styled.div`
     width: 100%;
     display: flex;
     align-items: center;
     justify-content: space-between;
     gap: 10px;
     margin-left: 15px;
     margin-right: 30px;
`;

export const GameHeaderRightSide = styled.div`
     display: flex;
     align-items: center;
`;

export const GameHeaderFlag = styled.img`
     width: 20px;
     height: auto;
`;

export const GameHeaderEvent = styled.span`
     font-weight: 500;
     margin-left: 15px;

     @media (max-width: 425px) {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 250px;
          font-size: 14px;
     }
`;

export const GameHeaderScore = styled.span`
     font-weight: 500;
`;

// GAME DATA SECTION
export const GameDataContainer = styled.a`
     width: 50%;
     height: 60px;
     margin: 0 auto;
     display: flex;
     justify-content: space-between;
     align-items: center;
     border-bottom: 1px solid #dfdfdf;
     font-size: 12px;
     text-decoration: none;
     color: #000000;

     &:hover {
          background-color: #DDDDDD;
          cursor: pointer;
     }

     @media (max-width: 425px) {
          width: 100%;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
     }
`;

export const GameDataLeft = styled.div`
     width: 50%;
     display: flex;
     align-items: center;
     padding-left: 15px;

     @media (max-width: 425px) {
          width: 70%;
     }
`;

export const GameDataLeftHour = styled.div`
     width: 30%;
     display: flex;
     flex-direction: column;
`;

export const GameDataLeftTeams = styled.div`
     display: flex;
     flex-direction: column;
     gap: 5px;
     flex: 1;
`;

export const GameDataLeftTeamA = styled.div`
     display: flex;
     gap: 20px;
     align-items: center;

     @media (max-width: 425px) {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 180px;
          margin-left: 10px;
     }
`;

export const GameDataLeftTeamALogo = styled.img`
     width: 18px;
     height: 18px;
`;

export const GameDataLeftTeamB = styled.div`
     display: flex;
     gap: 20px;
     align-items: center;

     @media (max-width: 425px) {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 180px;
          margin-left: 10px;
     }
`;

export const GameDataLeftTeamBLogo = styled.img`
     width: 18px;
     height: 18px;
`;

export const GameDataRight = styled.div`
     width: 50%;
     display: flex;
     align-items: center;
     justify-content: flex-end;
     padding-right: 15px;

     @media (max-width: 425px) {
          width: 30%;
     }
`;

export const GameDataRightScore = styled.div`
     width: 50%;
     display: flex;
     flex-direction: column;
     gap: 5px;

     @media (max-width: 425px) {
          width: 100%;
     }
`;

export const GameDataRightScoreValue = styled.span`
     flex: 1;
     display: flex;
     justify-content: flex-end;
`;

export const GameDataRightPenaltyValue = styled.span`
     flex: 1;
     display: flex;
     justify-content: flex-end;
     color: #999999;
`;

export const GameDataResult = styled.div`
     width: 25px;
     height: 25px;
     font-weight: bold;
     display: flex;
     background-color: ${props => props.gameResult === 'V' ? '#00a83f' : props.gameResult === 'D' ? '#dc0000' : '#f3a000'};
     color: #FFFFFF;
     justify-content: center;
     align-items: center;
`;

// FOOTER SECTION
export const FooterContainer = styled.div`
     width: 100%;
     height: auto;
     background: #FFFFFF;
     display: flex;
     justify-content: center;

     @media (max-width: 425px) {
          height: 500px;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          height: 430px;
     }
`;

export const FooterMainDiv = styled.div`
     display: flex;
     width: 50%;

     @media (max-width: 425px) {
          width: 100%;
          flex-direction: column;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
          flex-direction: column;
     }
`;

export const FooterBox = styled.div`
     flex: 1;
     padding: 30px;
     display: flex;
     flex-direction: column;
     gap: 20px;
`;

export const FooterHover = styled.span`
     display: flex;
     align-items: center;
     gap: 10px;
     font-size: 14px;

     &:hover {
          text-decoration: underline;
          cursor: pointer;
     }
`;

export const FooterLogo = styled.img`
     width: 200px;
`;

// MATCH HEADER SECTION
export const MatchHeaderContainer = styled.div`
     width: 100%;
     height: 250px;
     border-bottom: 1px solid #C8CDCD;
     display: flex;
     justify-content: center;
`;

export const MatchHeaderMainDiv = styled.div`
     width: 50%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;

     @media (max-width: 425px) {
          width: 100%;
          font-size: 14px;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 100%;
     }
`;

export const MatchHeaderTeamsContainer = styled.div`
     width: 100%;
     display: flex;
     justify-content: space-evenly;
     align-items: center;
`;

export const MatchHeaderTeamSlot = styled.div`
     display: flex;
     flex-direction: column;
     align-items: center;
     gap: 10px;
`;

export const MatchHeaderTeamName = styled.a`
     text-overflow: ellipsis;
     white-space: nowrap;
     text-align: center;
     overflow: hidden;
     width: 100px;
     text-decoration: none;
     color: #000000;

     &:hover {
          cursor: pointer;
          text-decoration: underline;
     }
`;

export const MatchHeaderScore = styled.span`
     font-size: 48px;
     font-weight: bold;
     color: #2178BD;

     @media (max-width: 425px) {
          font-size: 40px;
     }
`;

// LINEUP SECTION
export const LineupTitle = styled.div`
     display: flex;
     justify-content: center;
     align-items: center;
     width: 50%;
     height: 50px;
     margin: 0 auto;
     border-bottom: 1px solid #C8CDCD;
     font-weight: bold;

     @media (max-width: 425px) {
          width: 100%;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
     }
`;

export const LineupContainer = styled.div`
     display: flex;
     width: 50%;
     margin: 0 auto;

     @media (max-width: 425px) {
          width: 90%;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
     }
`;

export const LineupList = styled.ul`
     padding: 0;
     margin: 0;
`;

export const LineupListItemLeft = styled.li`
     list-style: none;
     display: flex;
     margin: 10px 0;
     font-weight: bold;
     font-size: 14px;
     align-items: center;
     height: 25px;
     padding-left: 10px;

     &:nth-child(odd) {
          background: #E7E7E7;
          padding: 10px;
     }

     @media (max-width: 425px) {
          margin: 20px 0;
     }
`;

export const LineupListItemLeftData = styled.div`
     width: 40px;
`;

export const LineupListItemRight = styled.li`
     list-style: none;
     display: flex;
     justify-content: flex-end;
     margin: 10px 0;
     font-weight: bold;
     font-size: 14px;
     align-items: center;
     height: 25px;
     padding-right: 10px;

     &:nth-child(odd) {
          background: #E7E7E7;
          padding: 10px;
     }
     
     @media (max-width: 425px) {
          text-align: right;
          margin: 20px 0;
     }
`;

export const LineupListItemRightData = styled.div`
     width: 40px;
     display: flex;
     justify-content: flex-end;
`;

export const LineupTeamFlag = styled.img`
     width: 25px;
     height: auto;
`;

// EVENTS SECTION
export const HomeTeamEvent = styled.span`
     display: flex;
     justify-content: flex-start;
     gap: 20px;
     margin: 10px 0;
     font-size: 0.875rem;
     align-items: center;
     font-weight: bold;
     padding-left: 10px;

     @media (max-width: 425px) {
          margin: 20px 0;
     }
`;

export const VisitorTeamEvent = styled.span`
     display: flex;
     justify-content: flex-end;
     gap: 20px;
     margin: 10px 0;
     font-size: 0.875rem;
     align-items: center;
     font-weight: bold;
     padding-right: 10px;

     @media (max-width: 425px) {
          margin: 20px 0;
     }
`;

export const EventPlayerName = styled.a`
     cursor: pointer;
     text-decoration: none;
     color: #000000;

     &:hover {
          text-decoration: underline;
     }
`;

export const EventPlayerSubstituted = styled.a`
     font-weight: 400;
     cursor: pointer;
     text-decoration: none;
     color: #000000;

     &:hover {
          text-decoration: underline;
     }
`;

// MATCH DETAILS SECTION
export const MatchDetailsList = styled.ul`
     width: 100%;
     padding: 0;
`;

export const MatchDetailsListItem = styled.li`
     list-style: none;
     margin: 10px 0;
     padding: 0 10px;

     &:nth-child(odd) {
          background: #E7E7E7;
          padding: 10px;
     }

     @media (max-width: 425px) {
          font-size: 14px;
          margin: 20px 0;
     }
`;

export const MatchDetailsIconAndTitle = styled.div`
     display: flex;
     justify-content: space-between;
     gap: 20px;
     font-weight: bold;
`;

// STATISTICS SECTION
export const StatisticsListItem = styled.li`
     list-style: none;
     margin: 10px 0;
     display: flex;
     justify-content: space-between;
     padding: 0 10px;

     &:nth-child(odd) {
          background: #E7E7E7;
          padding: 10px;
     }

     @media (max-width: 425px) {
          font-size: 14px;
          margin: 20px 0;
     }
`;

export const StatisticsListData = styled.div`
     display: flex;
     gap: 20px;
`;

// PLAYER DETAILS SECTION
export const PlayerDetailsContainer = styled.div`
     width: 100%;
     height: 250px;
     border-bottom: 1px solid #C8CDCD;
     display: flex;
     justify-content: center;
`;

export const PlayerDetailsMainDiv = styled.div`
     display: flex;
     justify-content: space-evenly;
     align-items: center;
     width: 50%;

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
     }
     
     @media (max-width: 425px) {
          width: 100%;
     }
`;

export const PlayerDetailsDataDiv = styled.div`
     display: flex;
     flex-direction: column;
     gap: 5px;
`;

export const PlayerName = styled.span`
     font-size: 22px;
     font-weight: bold;

     @media (max-width: 425px) {
          font-size: 18px;
     }
`;

export const PlayerDetailsDataLine = styled.div`
     display: flex;
     gap: 5px;
     font-size: 14px;

     @media (max-width: 425px) {
          flex-direction: column;
          gap: 0;
          font-size: 12px;
     }
`;

// TEAM DETAILS SECTION
export const TeamDetailsContainer = styled.div`
     width: 100%;
     height: 250px;
     border-bottom: 1px solid #C8CDCD;
     display: flex;
     justify-content: center;
`;

export const TeamDetailsMainDiv = styled.div`
     display: flex;
     justify-content: center;
     align-items: center;
     width: 50%;
     gap: 5px;
     flex-direction: column;

     @media (max-width: 425px) {
          width: 100%;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
     }
`;

export const TeamDetailsSecondaryDiv = styled.div`
     width: 50%;
     display: flex;
     justify-content: space-evenly;
     align-items: center;

     @media (max-width: 425px) {
          width: 100%;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
     }
`;

export const TeamDetailsTeamNameContainer = styled.div`
     display: flex;
     align-items: center;
     gap: 10px;
`;

export const TeamDetailsTeamName = styled.span`
     font-size: 24px;
     font-weight: bold;
`;

export const TeamDetailsTwitterContainer = styled.div`
     display: flex;
     align-items: center;
     gap: 5px;
`;

export const TeamDetailsLineupContainer = styled.div`
     display: flex;
     width: 50%;
     margin: 0 auto;
     flex-direction: column;

     @media (max-width: 425px) {
          width: 90%;
     }

     @media (min-width: 426px) and (max-width: 1024px) {
          width: 75%;
     }
`;

// COACH SECTION
export const CoachContainer = styled.div`
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     margin: 20px auto 0 auto;
`;

// NO DATA SECTION
export const NoDataFullPageContainer = styled.div`
     flex: 1;
     display: flex;
     justify-content: center;
     align-items: center;
`;

export const NoDataSmallContainer = styled.div`
     width: 100%;
     height: 100px;
     display: flex;
     align-items: center;
     justify-content: center;
`;

export const NoDataMainDiv = styled.div`
     width: 50%;
`;

export const NoDataSpan = styled.span`
     display: flex;
     justify-content: center;
     align-items: center;
     gap: 10px;
`;

// TRANSFERS SECTION
export const TransfersHeader = styled.span`
     width: 20%;
     font-weight: bold;
     text-align: center;
`;

export const TransfersListItemContainer = styled.div`
     width: 20%;
     font-size: 14px;
     display: flex;
     align-items: center;
     gap: 10px;
`;

export const TransfersListItemTeamFlag = styled.img`
     width: 20px;
     height: 20px;
`;

export const TransfersListItemTeamName = styled.a`
     font-size: 14px;
     font-weight: 500;
     cursor: pointer;
     text-decoration: none;
     color: #000000;

     &:hover {
          text-decoration: underline;
     }
`;

// SQUAD SECTION
export const SquadListHeader = styled.span`
     width: 20%;
     display: flex;
     justify-content: end;
`;

export const SquadListItemImg = styled.img`
     width: 25px;
     height: 'auto';
     margin-right: 15px;
`;

export const SquadListItemPlayer = styled.a`
     font-size: 14px;
     font-weight: 500;
     cursor: pointer;
     text-decoration: none;
     color: #000000;

     &:hover {
          text-decoration: underline;
     }
`;

export const SquadListItemRight = styled.span`
     width: 20%;
     font-size: 14px;
     display: flex;
     justify-content: end;
`;

// MORE MATCHES SECTION
export const MoreMatchesContainer = styled.div`
     width: 100%;
     height: 50px;
     margin: 0 auto;
     display: flex;
     justify-content: center;
     align-items: center;
`;

export const MoreMatchesLink = styled.a`
     cursor: pointer;
     text-decoration: none;
     color: #000000;
     font-size: 12px;

     &:hover {
          text-decoration: underline;
     }
`;