import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import TeamHeader from '../components/TeamDetailsSection/TeamHeader/TeamHeader';
import { Container, MainContainer, MoreMatchesContainer, MoreMatchesLink, NoDataFullPageContainer, NoDataMainDiv, NoDataSpan } from '../styles';

// Icons
import ErrorIcon from '@mui/icons-material/Error';
import GameHeader from '../components/ResultsSection/GameHeader/GameHeader';
import { setGameStatus } from '../functions';
import GameData from '../components/ResultsSection/GameData/GameData';

const TeamNextMatches = () => {
     const { id } = useParams();

     const [team, setTeam] = useState([]);
     const [upcomingMatches, setUpcomingMatches] = useState([]);
     const [initialUpcomingMatchesArray, setInitialLatestMatchesArray] = useState([]);
     const [next, setNext] = useState(10);

     const loadMore = () => {
          if (next > upcomingMatches.length) return null;
          setNext(next + 10);
          const newArray = upcomingMatches.slice(0, next);
          setInitialLatestMatchesArray(newArray);
     };

     useEffect(() => {
          async function getteamData() {
               const response = await axios
                    .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/teams/${id}?
                         api_token=${process.env.REACT_APP_API_TOKEN}
                         &include=country,coach,latest,upcoming,stats
                         &tz=America/Fortaleza`);
               setTeam(response.data.data);
               const finalUpcomingMatchesArray = await Promise.all(
                    response.data.data.upcoming?.data?.map(async (item) => {
                         const object = {};
                         const rivalTeamId = item.localteam_id === Number(id) ? item.visitorteam_id : item.localteam_id;
                         const rivalTeamData = await axios
                              .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/teams/${rivalTeamId}?
                          api_token=${process.env.REACT_APP_API_TOKEN}`);
                         object.match_id = item.id;
                         object.status = item.time.status;
                         object.local_name = response.data.data.name;
                         object.local_flag = response.data.data.logo_path;
                         object.rival_name = rivalTeamData.data.data.name;
                         object.rival_flag = rivalTeamData.data.data.logo_path;
                         object.date = `${item.time?.starting_at?.date?.split('-')[2]}/${item.time?.starting_at?.date?.split('-')[1]}/${item.time?.starting_at?.date?.split('-')[0]}`;
                         return object;
                    }));
               setUpcomingMatches(finalUpcomingMatchesArray);
               setInitialLatestMatchesArray(finalUpcomingMatchesArray.slice(0, 10));
          };
          if (id !== 'null') getteamData();
     }, [id]);

     if (id === 'null') return (
          <NoDataFullPageContainer>
               <NoDataMainDiv>
                    <NoDataSpan>
                         <ErrorIcon
                              sx={{
                                   fontSize: 22,
                                   color: '#2178BD'
                              }}
                         />
                         Não há dados para o time selecionado.
                    </NoDataSpan>
               </NoDataMainDiv>
          </NoDataFullPageContainer>
     );

     if (team.length === 0) return null;

     return (
          <Container>
               <Header />
               <MainContainer>
                    <TeamHeader
                         team={team}
                    />
                    <GameHeader
                         event='Próximas partidas do time'
                    />
                    {
                         initialUpcomingMatchesArray.map((game, index) => {
                              const status = setGameStatus(game.status);
                              return <GameData
                                   key={index}
                                   id={game.match_id}
                                   time={game.date}
                                   logoTeamA={game.local_flag}
                                   teamA={game.local_name}
                                   logoTeamB={game.rival_flag}
                                   teamB={game.rival_name}
                                   scoreA={game.local_score}
                                   scoreB={game.rival_score}
                                   status={status}
                                   scorePenaltyA={''}
                                   scorePenaltyB={''}
                                   gameResult={game.result}
                              />
                         })
                    }
                    {
                         next > upcomingMatches.length ?
                              '' :
                              <MoreMatchesContainer>
                                   <MoreMatchesLink
                                        onClick={loadMore}
                                   >
                                        Ver mais partidas
                                   </MoreMatchesLink>
                              </MoreMatchesContainer>
                    }
               </MainContainer>
               <Footer />
          </Container>
     );
};

export default TeamNextMatches;