import React from 'react';

// Logo
import logo from '../../coloredlogo.png';

// Styled components
import {
     BoldFont,
     Font12,
     FooterBox,
     FooterContainer,
     FooterHover,
     FooterLogo,
     FooterMainDiv
} from '../../styles';

// Icons
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
     return (
          <FooterContainer>
               <FooterMainDiv>
                    <FooterBox>
                         <FooterLogo
                              src={logo}
                              alt='HunterScores'
                         />
                         <Font12>
                              © 2023 por HunterScores.com
                         </Font12>
                    </FooterBox>
                    <FooterBox>
                         <Font12>
                              O HunterScores.com oferece os resultados de diversas
                              partidas dos mais variados esportes por todo o mundo,
                              incluindo as ligas de futebol mais famosas, como a
                              Premier League, a LaLiga, a Bundesliga, a UEFA Champions
                              League, entre outras. Você também encontra detalhes
                              das estatísticas da partida, como chutes a gol, cartões
                              amarelos e vermelhos, substituições, e muito mais.
                         </Font12>
                    </FooterBox>
                    <FooterBox>
                         <BoldFont>
                              Siga-nos
                         </BoldFont>
                         <FooterHover>
                              <TwitterIcon sx={{ fontSize: 20, color: '#2178BD' }} />
                              Twitter
                         </FooterHover>
                         <FooterHover>
                              <InstagramIcon sx={{ fontSize: 20, color: '#2178BD' }} />
                              Instagram
                         </FooterHover>
                    </FooterBox>
               </FooterMainDiv>
          </FooterContainer>
     );
};

export default Footer;