import React from 'react';

// Components imported in the screen
import GameHeader from '../../../ResultsSection/GameHeader/GameHeader';

// Styled components
import {
     BoldFont,
     DisplayFlexAndFlexOne,
     SquadListItemPlayer,
     LineupTitle,
     MatchDetailsList,
     MatchDetailsListItem,
     SpaceBetween,
     SquadListHeader,
     SquadListItemRight,
     TeamDetailsLineupContainer,
     SquadListItemImg
} from '../../../../styles';

// Icons
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SquareIcon from '@mui/icons-material/Square';

const Squad = (props) => {
     const squad = props.squad;

     return (
          <>
               <GameHeader
                    event='Equipe'
               />
               <TeamDetailsLineupContainer>
                    <MatchDetailsList>
                         <LineupTitle>
                              Goleiros
                         </LineupTitle>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <DisplayFlexAndFlexOne>
                                        <BoldFont>Nome</BoldFont>
                                   </DisplayFlexAndFlexOne>
                                   <DisplayFlexAndFlexOne>
                                        <SquadListHeader
                                             title='Idade'
                                        >
                                             <EventRepeatIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Partidas jogadas'
                                        >
                                             <PersonAddIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Gols marcados'
                                        >
                                             <SportsSoccerIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Cartões amarelos'
                                        >
                                             <SquareIcon sx={{ width: 20 }} style={{ color: '#FFCD00' }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Cartões vermelhos'
                                        >
                                             <SquareIcon sx={{ width: 20 }} style={{ color: '#DC0000' }} />
                                        </SquadListHeader>
                                   </DisplayFlexAndFlexOne>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         {
                              squad.filter(player => player.position === 'Goleiro')
                                   .map((player, index) => {
                                        return <MatchDetailsListItem key={index}>
                                             <SpaceBetween>
                                                  <DisplayFlexAndFlexOne>
                                                       <SquadListItemImg
                                                            src={player.country_flag}
                                                            alt=''
                                                       />
                                                       <SquadListItemPlayer
                                                            href={`/player/${player.player_id}`}
                                                            target='_blank'
                                                       >
                                                            {player.name}
                                                       </SquadListItemPlayer>
                                                  </DisplayFlexAndFlexOne>
                                                  <DisplayFlexAndFlexOne>
                                                       <SquadListItemRight>
                                                            {player.age}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.appearences}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.goals}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.yellow_cards}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.red_cards}
                                                       </SquadListItemRight>
                                                  </DisplayFlexAndFlexOne>
                                             </SpaceBetween>
                                        </MatchDetailsListItem>
                                   })
                         }
                         <LineupTitle>
                              Zagueiros
                         </LineupTitle>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <DisplayFlexAndFlexOne>
                                        <BoldFont>Nome</BoldFont>
                                   </DisplayFlexAndFlexOne>
                                   <DisplayFlexAndFlexOne>
                                        <SquadListHeader
                                             title='Idade'
                                        >
                                             <EventRepeatIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Partidas jogadas'
                                        >
                                             <PersonAddIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Gols marcados'
                                        >
                                             <SportsSoccerIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Cartões amarelos'
                                        >
                                             <SquareIcon sx={{ width: 20 }} style={{ color: '#FFCD00' }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Cartões vermelhos'
                                        >
                                             <SquareIcon sx={{ width: 20 }} style={{ color: '#DC0000' }} />
                                        </SquadListHeader>
                                   </DisplayFlexAndFlexOne>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         {
                              squad.filter(player => player.position === 'Zagueiro')
                                   .map((player, index) => {
                                        return <MatchDetailsListItem key={index}>
                                             <SpaceBetween>
                                                  <DisplayFlexAndFlexOne>
                                                       <SquadListItemImg
                                                            src={player.country_flag}
                                                            alt=''
                                                       />
                                                       <SquadListItemPlayer
                                                            href={`/player/${player.player_id}`}
                                                            target='_blank'
                                                       >
                                                            {player.name}
                                                       </SquadListItemPlayer>
                                                  </DisplayFlexAndFlexOne>
                                                  <DisplayFlexAndFlexOne>
                                                       <SquadListItemRight>
                                                            {player.age}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.appearences}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.goals}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.yellow_cards}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.red_cards}
                                                       </SquadListItemRight>
                                                  </DisplayFlexAndFlexOne>
                                             </SpaceBetween>
                                        </MatchDetailsListItem>
                                   })
                         }
                         <LineupTitle>
                              Meio-campistas
                         </LineupTitle>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <DisplayFlexAndFlexOne>
                                        <BoldFont>Nome</BoldFont>
                                   </DisplayFlexAndFlexOne>
                                   <DisplayFlexAndFlexOne>
                                        <SquadListHeader
                                             title='Idade'
                                        >
                                             <EventRepeatIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Partidas jogadas'
                                        >
                                             <PersonAddIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Gols marcados'
                                        >
                                             <SportsSoccerIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Cartões amarelos'
                                        >
                                             <SquareIcon sx={{ width: 20 }} style={{ color: '#FFCD00' }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Cartões vermelhos'
                                        >
                                             <SquareIcon sx={{ width: 20 }} style={{ color: '#DC0000' }} />
                                        </SquadListHeader>
                                   </DisplayFlexAndFlexOne>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         {
                              squad.filter(player => player.position === 'Meio-campo')
                                   .map((player, index) => {
                                        return <MatchDetailsListItem key={index}>
                                             <SpaceBetween>
                                                  <DisplayFlexAndFlexOne>
                                                       <SquadListItemImg
                                                            src={player.country_flag}
                                                            alt=''
                                                       />
                                                       <SquadListItemPlayer
                                                            href={`/player/${player.player_id}`}
                                                            target='_blank'
                                                       >
                                                            {player.name}
                                                       </SquadListItemPlayer>
                                                  </DisplayFlexAndFlexOne>
                                                  <DisplayFlexAndFlexOne>
                                                       <SquadListItemRight>
                                                            {player.age}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.appearences}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.goals}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.yellow_cards}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.red_cards}
                                                       </SquadListItemRight>
                                                  </DisplayFlexAndFlexOne>
                                             </SpaceBetween>
                                        </MatchDetailsListItem>
                                   })
                         }
                         <LineupTitle>
                              Atacantes
                         </LineupTitle>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <DisplayFlexAndFlexOne>
                                        <BoldFont>Nome</BoldFont>
                                   </DisplayFlexAndFlexOne>
                                   <DisplayFlexAndFlexOne>
                                        <SquadListHeader
                                             title='Idade'
                                        >
                                             <EventRepeatIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Partidas jogadas'
                                        >
                                             <PersonAddIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Gols marcados'
                                        >
                                             <SportsSoccerIcon sx={{ width: 20 }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Cartões amarelos'
                                        >
                                             <SquareIcon sx={{ width: 20 }} style={{ color: '#FFCD00' }} />
                                        </SquadListHeader>
                                        <SquadListHeader
                                             title='Cartões vermelhos'
                                        >
                                             <SquareIcon sx={{ width: 20 }} style={{ color: '#DC0000' }} />
                                        </SquadListHeader>
                                   </DisplayFlexAndFlexOne>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         {
                              squad.filter(player => player.position === 'Atacante')
                                   .map((player, index) => {
                                        return <MatchDetailsListItem key={index}>
                                             <SpaceBetween>
                                                  <DisplayFlexAndFlexOne>
                                                       <SquadListItemImg
                                                            src={player.country_flag}
                                                            alt=''
                                                       />
                                                       <SquadListItemPlayer
                                                            href={`/player/${player.player_id}`}
                                                            target='_blank'
                                                       >
                                                            {player.name}
                                                       </SquadListItemPlayer>
                                                  </DisplayFlexAndFlexOne>
                                                  <DisplayFlexAndFlexOne>
                                                       <SquadListItemRight>
                                                            {player.age}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.appearences}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.goals}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.yellow_cards}
                                                       </SquadListItemRight>
                                                       <SquadListItemRight>
                                                            {player.red_cards}
                                                       </SquadListItemRight>
                                                  </DisplayFlexAndFlexOne>
                                             </SpaceBetween>
                                        </MatchDetailsListItem>
                                   })
                         }
                    </MatchDetailsList>
               </TeamDetailsLineupContainer>
          </>
     );
};

export default Squad;