import React from 'react';

// Components imported in the screen
import GameHeader from '../../../ResultsSection/GameHeader/GameHeader';

// Styled components
import {
     LineupContainer,
     MatchDetailsList,
     MatchDetailsListItem,
     NoDataMainDiv,
     NoDataSmallContainer,
     NoDataSpan,
     SpaceBetween,
     TransfersHeader,
     TransfersListItemContainer,
     TransfersListItemTeamFlag,
     TransfersListItemTeamName
} from '../../../../styles';

// Icons
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ErrorIcon from '@mui/icons-material/Error';

const PlayerTransfers = (props) => {
     const transfers = props.transfers;

     return (
          <>
               <GameHeader
                    event='Transferências'
               />
               <LineupContainer>
                    {
                         transfers.length > 0 ?
                              <MatchDetailsList>
                                   <MatchDetailsListItem>
                                        <SpaceBetween>
                                             <TransfersHeader>
                                                  Data
                                             </TransfersHeader>
                                             <TransfersHeader>
                                                  Origem
                                             </TransfersHeader>
                                             <TransfersHeader>
                                                  Tipo
                                             </TransfersHeader>
                                             <TransfersHeader>
                                                  Destino
                                             </TransfersHeader>
                                        </SpaceBetween>
                                   </MatchDetailsListItem>
                                   {
                                        transfers.map((item, index) => {
                                             return <MatchDetailsListItem
                                                  key={index}
                                             >
                                                  <SpaceBetween>
                                                       <TransfersListItemContainer>
                                                            {item.date}
                                                       </TransfersListItemContainer>
                                                       <TransfersListItemContainer>
                                                            <TransfersListItemTeamFlag
                                                                 src={item.fromTeamFlag}
                                                                 alt=''
                                                            />
                                                            <TransfersListItemTeamName
                                                                 href={`/team/${item.fromTeamId}`}
                                                                 target='_blank'
                                                            >
                                                                 {item.fromTeamName}
                                                            </TransfersListItemTeamName>
                                                       </TransfersListItemContainer>
                                                       <TransfersListItemContainer>
                                                            <ArrowForwardIcon
                                                                 sx={{ color: '#00A83F' }}
                                                            />
                                                            {item.transfer}
                                                       </TransfersListItemContainer>
                                                       <TransfersListItemContainer>
                                                            <TransfersListItemTeamFlag
                                                                 src={item.toTeamFlag}
                                                                 alt=''
                                                            />
                                                            <TransfersListItemTeamName
                                                                 href={`/team/${item.toTeamId}`}
                                                                 target='_blank'
                                                            >
                                                                 {item.toTeamName}
                                                            </TransfersListItemTeamName>
                                                       </TransfersListItemContainer>
                                                  </SpaceBetween>
                                             </MatchDetailsListItem>
                                        })
                                   }
                              </MatchDetailsList>
                              :
                              <NoDataSmallContainer>
                                   <NoDataMainDiv>
                                        <NoDataSpan>
                                             <ErrorIcon
                                                  sx={{
                                                       fontSize: 22,
                                                       color: '#2178BD'
                                                  }}
                                             />
                                             Não há dados para o jogador selecionado.
                                        </NoDataSpan>
                                   </NoDataMainDiv>
                              </NoDataSmallContainer>
                    }
               </LineupContainer>
          </>
     );
};

export default PlayerTransfers;