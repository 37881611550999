import React from 'react';

// Components imported in the screen
import GameHeader from '../../../ResultsSection/GameHeader/GameHeader';

import {
     CoachContainer,
     LineupTitle,
     MatchDetailsIconAndTitle,
     MatchDetailsList,
     MatchDetailsListItem,
     Picture,
     PictureContainer,
     SpaceBetween,
     TeamDetailsLineupContainer
} from '../../../../styles';

const TeamDetails = (props) => {
     const team = props.team;
     const coachAge = props.coachAge;

     return (
          <>
               <GameHeader
                    event='Detalhes do time'
               />
               <TeamDetailsLineupContainer>
                    <MatchDetailsList>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        Ano de fundação
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {team.founded ?? 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                    </MatchDetailsList>
                    <LineupTitle>
                         Técnico
                    </LineupTitle>
                    <CoachContainer>
                         <PictureContainer>
                              <Picture
                                   src={team?.coach?.data?.image_path || 'https://cdn.sportmonks.com/images/soccer/placeholder.png'}
                                   alt=''
                              />
                         </PictureContainer>
                    </CoachContainer>
                    <MatchDetailsList>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        Nome
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {team?.coach?.data?.fullname ?? 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        Idade
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {coachAge ? `${coachAge} anos` : 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        Data de nascimento
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {team?.coach?.data?.birthdate ?? 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        Local de nascimento
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {team?.coach?.data?.birthplace ?? 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        País
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {team?.coach?.data?.birthcountry ?? 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                    </MatchDetailsList>
               </TeamDetailsLineupContainer>
          </>
     );
};

export default TeamDetails;