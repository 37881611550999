import React from 'react';

// Components imported in the screen
import GameData from '../../../ResultsSection/GameData/GameData';
import GameHeader from '../../../ResultsSection/GameHeader/GameHeader';

import { setGameStatus } from '../../../../functions';
import { MoreMatchesContainer, MoreMatchesLink } from '../../../../styles';

const NextMatches = (props) => {
     const team = props.team;
     const upcomingMatches = props.upcomingMatches;

     return (
          <>
               <GameHeader
                    event='Próximas 10 partidas'
               />
               {
                    upcomingMatches.map((game, index) => {
                         const status = setGameStatus(game.status);
                         return <GameData
                              key={index}
                              id={game.match_id}
                              time={game.date}
                              logoTeamA={game.local_flag}
                              teamA={game.local_name}
                              logoTeamB={game.rival_flag}
                              teamB={game.rival_name}
                              scoreA={''}
                              scoreB={''}
                              status={status}
                              scorePenaltyA={''}
                              scorePenaltyB={''}
                         />
                    })
               }
               <MoreMatchesContainer>
                    <MoreMatchesLink
                         href={`/team/${team.id}/nextmatches`}
                         target='_blank'
                    >
                         Ver mais partidas
                    </MoreMatchesLink>
               </MoreMatchesContainer>
          </>
     );
};

export default NextMatches;