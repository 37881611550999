import React from 'react';

// Components imported in the screen
import GameHeader from '../../../ResultsSection/GameHeader/GameHeader';

// Styled components
import {
     EventPlayerName,
     FlexOne,
     LineupContainer,
     LineupList,
     LineupListItemLeft,
     LineupListItemLeftData,
     LineupListItemRight,
     LineupListItemRightData,
     LineupTeamFlag,
     LineupTitle
} from '../../../../styles';

const Lineup = (props) => {
     const lineup = props.lineup;
     const bench = props.bench;
     const localteamid = props.localteamid;
     const visitorteamid = props.visitorteamid;
     const localteamflag = props.localteamflag;
     const visitorteamflag = props.visitorteamflag;

     return (
          <>
               <GameHeader
                    event='Escalação'
               />
               <LineupTitle>
                    Titulares
               </LineupTitle>
               <LineupContainer>
                    <FlexOne>
                         <LineupList>
                              {
                                   lineup?.map((player, index) => (
                                        localteamid === player.team_id ?
                                             <LineupListItemLeft
                                                  key={index}
                                             >
                                                  <LineupListItemLeftData>
                                                       {player.number}
                                                  </LineupListItemLeftData>
                                                  <LineupListItemLeftData>
                                                       <LineupTeamFlag
                                                            alt=''
                                                            src={localteamflag}
                                                       />
                                                  </LineupListItemLeftData>
                                                  <EventPlayerName
                                                       href={`/player/${player.player_id}`}
                                                       target='_blank'
                                                  >
                                                       {player.player_name}
                                                  </EventPlayerName>
                                             </LineupListItemLeft>
                                             :
                                             undefined
                                   ))
                              }
                         </LineupList>
                    </FlexOne>
                    <FlexOne>
                         <LineupList>
                              {
                                   lineup?.map((player, index) => (
                                        visitorteamid === player.team_id ?
                                             <LineupListItemRight
                                                  key={index}
                                             >
                                                  <EventPlayerName
                                                       href={`/player/${player.player_id}`}
                                                       target='_blank'
                                                  >
                                                       {player.player_name}
                                                  </EventPlayerName>
                                                  <LineupListItemRightData>
                                                       <LineupTeamFlag
                                                            alt=''
                                                            src={visitorteamflag}
                                                       />
                                                  </LineupListItemRightData>
                                                  <LineupListItemRightData>
                                                       {player.number}
                                                  </LineupListItemRightData>
                                             </LineupListItemRight>
                                             :
                                             undefined
                                   ))
                              }
                         </LineupList>
                    </FlexOne>
               </LineupContainer>
               <LineupTitle>
                    Substitutos
               </LineupTitle>
               <LineupContainer>
                    <FlexOne>
                         <LineupList>
                              {
                                   bench?.map((player, index) => (
                                        localteamid === player.team_id ?
                                             <LineupListItemLeft
                                                  key={index}
                                             >
                                                  <LineupListItemLeftData>
                                                       {player.number}
                                                  </LineupListItemLeftData>
                                                  <LineupListItemLeftData>
                                                       <LineupTeamFlag
                                                            alt=''
                                                            src={localteamflag}
                                                       />
                                                  </LineupListItemLeftData>
                                                  <EventPlayerName
                                                       href={`/player/${player.player_id}`}
                                                       target='_blank'
                                                  >
                                                       {player.player_name}
                                                  </EventPlayerName>
                                             </LineupListItemLeft>
                                             :
                                             undefined
                                   ))
                              }
                         </LineupList>
                    </FlexOne>
                    <FlexOne>
                         <LineupList>
                              {
                                   bench?.map((player, index) => (
                                        visitorteamid === player.team_id ?
                                             <LineupListItemRight
                                                  key={index}
                                             >
                                                  <EventPlayerName
                                                       href={`/player/${player.player_id}`}
                                                       target='_blank'
                                                  >
                                                       {player.player_name}
                                                  </EventPlayerName>
                                                  <LineupListItemRightData>
                                                       <LineupTeamFlag
                                                            alt=''
                                                            src={visitorteamflag}
                                                       />
                                                  </LineupListItemRightData>
                                                  <LineupListItemRightData>
                                                       {player.number}
                                                  </LineupListItemRightData>
                                             </LineupListItemRight>
                                             :
                                             undefined
                                   ))
                              }
                         </LineupList>
                    </FlexOne>
               </LineupContainer>
          </>
     );
};

export default Lineup;