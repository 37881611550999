import React from 'react';

// Styled components
import {
     ActiveNavigationBarListItem,
     NavigationBarContainer,
     NavigationBarList,
     NavigationBarListItem
} from '../../styles';

const navigationItems = [
     {
          name: 'Todos os jogos',
          value: 'all'
     },
     {
          name: 'Ao vivo',
          value: 'live'
     },
     {
          name: 'Finalizados',
          value: 'finished'
     },
     {
          name: 'Agendados',
          value: 'scheduled'
     },
];

const GameOptions = (props) => {
     const option = props.option;
     
     return (
          <NavigationBarContainer>
               <NavigationBarList>
                    {
                         navigationItems.map((item, index) => {
                              if (option === item.value) {
                                   return <ActiveNavigationBarListItem
                                        key={index}
                                        onClick={() => props.handleGameOptions(item.value)}
                                   >
                                        {item.name}
                                   </ActiveNavigationBarListItem>
                              } else {
                                   return <NavigationBarListItem
                                        key={index}
                                        onClick={() => props.handleGameOptions(item.value)}
                                   >
                                        {item.name}
                                   </NavigationBarListItem>
                              }
                         })
                    }
               </NavigationBarList>
          </NavigationBarContainer>
     );
};

export default GameOptions;