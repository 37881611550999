import React from 'react';

// Styled components
import {
     MatchHeaderContainer,
     CountryAndLeagueHeader,
     MatchHeaderMainDiv,
     MatchHeaderScore,
     PictureContainer,
     MatchHeaderTeamsContainer,
     MatchHeaderTeamSlot,
     MatchHeaderTeamName,
     Picture,
     Blink,
     CountryAndLeagueHeaderLogo
} from '../../../styles';

// Functions
import {
     setGameStatus
} from '../../../functions';

const MatchHeader = (props) => {
     const league = props.league;
     const leagueflag = props.leagueflag;
     const date = props.date;
     const hour = props.hour;
     const scores = props.scores;
     const status = setGameStatus(props.status);
     const minute = props.minute;
     const added = props.added;
     const localteamflag = props.localteamflag;
     const localteamname = props.localteamname;
     const localteamid = props.localteamid;
     const visitorteamflag = props.visitorteamflag;
     const visitorteamname = props.visitorteamname;
     const visitorteamid = props.visitorteamid;

     return (
          <MatchHeaderContainer>
               <MatchHeaderMainDiv>
                    <CountryAndLeagueHeader>
                         <CountryAndLeagueHeaderLogo
                              src={leagueflag}
                              alt=''
                         />
                         {league}
                    </CountryAndLeagueHeader>
                    <MatchHeaderTeamsContainer>
                         <MatchHeaderTeamSlot>
                              <PictureContainer
                                   href={`/team/${localteamid}`}
                                   target='_blank'
                              >
                                   <Picture src={localteamflag} alt='' />
                              </PictureContainer>
                              <MatchHeaderTeamName
                                   href={`/team/${localteamid}`}
                                   target='_blank'
                              >
                                   {
                                        status === 'Finalizado' && scores.localteam_score > scores.visitorteam_score ?
                                             <span style={{ fontWeight: 'bold', color: '#2178BD' }}>{localteamname}</span> :
                                             <span>{localteamname}</span>
                                   }
                              </MatchHeaderTeamName>
                         </MatchHeaderTeamSlot>
                         <MatchHeaderTeamSlot>
                              <span>
                                   {date.split('-')[2]}.{date.split('-')[1]}.{date.split('-')[0]} {hour}
                              </span>
                              <MatchHeaderScore>
                                   {scores.localteam_score} - {scores.visitorteam_score}
                              </MatchHeaderScore>
                              <span style={{
                                   color: status.toUpperCase() === 'AO VIVO' ? '#FF0046' : '',
                                   fontWeight: status.toUpperCase() === 'AO VIVO' ? 'bold' : ''
                              }}>
                                   {status.toUpperCase()}
                                   {
                                        status.toUpperCase() === 'AO VIVO'
                                             ? added
                                                  ?
                                                  <span style={{
                                                       marginLeft: 4
                                                  }}>
                                                       • {minute}<Blink>'</Blink> + {added}
                                                  </span>
                                                  :
                                                  <span style={{
                                                       marginLeft: 4
                                                  }}>
                                                       • {minute}<Blink>'</Blink>
                                                  </span>
                                             : ''
                                   }
                              </span>
                         </MatchHeaderTeamSlot>
                         <MatchHeaderTeamSlot>
                              <PictureContainer
                                   href={`/team/${visitorteamid}`}
                                   target='_blank'
                              >
                                   <Picture src={visitorteamflag} alt='' />
                              </PictureContainer>
                              <MatchHeaderTeamName
                                   href={`/team/${visitorteamid}`}
                                   target='_blank'
                              >
                                   {
                                        status === 'Finalizado' && scores.visitorteam_score > scores.localteam_score ?
                                             <span style={{ fontWeight: 'bold', color: '#2178BD' }}>{visitorteamname}</span> :
                                             <span>{visitorteamname}</span>
                                   }
                              </MatchHeaderTeamName>
                         </MatchHeaderTeamSlot>
                    </MatchHeaderTeamsContainer>
               </MatchHeaderMainDiv>
          </MatchHeaderContainer>
     );
};

export default MatchHeader;