import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Components imported in the screen
import PlayerData from './PlayerData/PlayerData';
import PlayerHeader from './PlayerHeader/PlayerHeader';

// Styled components
import { Container, NoDataFullPageContainer, NoDataMainDiv, NoDataSpan } from '../../styles';

// Icons
import ErrorIcon from '@mui/icons-material/Error';

// Functions
import {
     setTransferStatus
} from '../../functions';

const PlayerDetailsSection = () => {
     const { id } = useParams();

     const [player, setPlayer] = useState([]);
     const [transfers, setTransfers] = useState([]);

     useEffect(() => {
          async function getPlayerData() {
               const response = await axios
                    .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/players/${id}?
                         api_token=${process.env.REACT_APP_API_TOKEN}
                         &include=position,team,stats,trophies,sidelined,transfers,lineups,country
                         &tz=America/Fortaleza`);
               setPlayer(response.data.data);
               const transferArray = await Promise.all(
                    response.data.data.transfers?.data?.filter((item) => {
                         if (item.type === 'IN') return false;
                         return true;
                    }).map(async (item) => {
                         const object = {};
                         const fromTeamId = item.from_team_id;
                         const toTeamId = item.to_team_id;
                         const fromTeamData = await axios
                              .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/teams/${fromTeamId}?
                              api_token=${process.env.REACT_APP_API_TOKEN}`);
                         const toTeamData = await axios
                              .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/teams/${toTeamId}?
                              api_token=${process.env.REACT_APP_API_TOKEN}`);
                         object.type = item.type;
                         object.transfer = setTransferStatus(item.type, item.transfer);
                         object.date = `${item.date.split('-')[2]}/${item.date.split('-')[1]}/${item.date.split('-')[0]}`;
                         object.fromTeamId = fromTeamId;
                         object.toTeamId = toTeamId;
                         object.fromTeamName = fromTeamData.data.data.name;
                         object.fromTeamFlag = fromTeamData.data.data.logo_path;
                         object.toTeamName = toTeamData.data.data.name;
                         object.toTeamFlag = toTeamData.data.data.logo_path;
                         return object;
                    }));

               setTransfers(transferArray);
          };
          if (id !== 'null') getPlayerData();
     }, [id]);

     if (id === 'null') return (
          <NoDataFullPageContainer>
               <NoDataMainDiv>
                    <NoDataSpan>
                         <ErrorIcon
                              sx={{
                                   fontSize: 22,
                                   color: '#2178BD'
                              }}
                         />
                         Não há dados para o jogador selecionado.
                    </NoDataSpan>
               </NoDataMainDiv>
          </NoDataFullPageContainer>
     );

     if (player.length === 0) return null;

     return (
          <Container>
               <PlayerHeader
                    player={player}
               />
               <PlayerData
                    player={player}
                    transfers={transfers}
               />
          </Container>
     );
};

export default PlayerDetailsSection;