import React from 'react';

// Styled components
import {
     BoldFont,
     Picture,
     PictureContainer,
     PlayerDetailsContainer,
     PlayerDetailsDataDiv,
     PlayerDetailsDataLine,
     PlayerDetailsMainDiv,
     PlayerName,
} from '../../../styles';

// Functions
import {
     setPlayerPosition
} from '../../../functions';

const PlayerHeader = (props) => {
     const player = props.player;
     const position = setPlayerPosition(player?.position?.data?.name);

     return (
          <PlayerDetailsContainer>
               <PlayerDetailsMainDiv>
                    <PictureContainer>
                         <Picture
                              src={player.image_path}
                              alt=''
                         />
                    </PictureContainer>
                    <PlayerDetailsDataDiv>
                         <PlayerName>
                              {player.display_name}
                         </PlayerName>
                         <PlayerDetailsDataLine>
                              <BoldFont>
                                   {position}
                              </BoldFont>
                              {player.team?.data?.name ? `(${player.team?.data?.name})` : ''}
                         </PlayerDetailsDataLine>
                    </PlayerDetailsDataDiv>
                    <PictureContainer
                         href={`/team/${player?.team_id}`}
                         target='_blank'
                    >
                         <Picture
                              src={player.team?.data?.logo_path || 'https://cdn.sportmonks.com/images/soccer/team_placeholder.png'}
                              alt=''
                         />
                    </PictureContainer>
               </PlayerDetailsMainDiv>
          </PlayerDetailsContainer>
     );
};

export default PlayerHeader;