import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Components imported in the screen
import TeamData from './TeamData/TeamData';
import TeamHeader from './TeamHeader/TeamHeader';

// Styled components
import {
     Container,
     NoDataFullPageContainer,
     NoDataMainDiv,
     NoDataSpan
} from '../../styles';

// Icons
import ErrorIcon from '@mui/icons-material/Error';

// Functions
import {
     setAge,
     setPlayerPosition,
     setTransferStatus
} from '../../functions';

const TeamDetailsSection = () => {
     const { id } = useParams();

     const [team, setTeam] = useState([]);
     const [squad, setSquad] = useState([]);
     const [transfers, setTransfers] = useState([]);
     const [latestMatches, setLatestMatches] = useState([]);
     const [upcomingMatches, setUpcomingMatches] = useState([]);

     useEffect(() => {
          async function getteamData() {
               const response = await axios
                    .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/teams/${id}?
                         api_token=${process.env.REACT_APP_API_TOKEN}
                         &include=country,coach,latest,upcoming,stats,transfers,squad
                         &tz=America/Fortaleza`);
               setTeam(response.data.data);
               const squadArray = await Promise.all(
                    response.data.data.squad?.data?.map(async (item) => {
                         const object = {};
                         const playerData = await axios
                              .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/players/${item.player_id}?
                              api_token=${process.env.REACT_APP_API_TOKEN}
                              &include=position,team,stats,trophies,sidelined,transfers,lineups,country
                              &tz=America/Fortaleza`);
                         object.country_flag = playerData.data.data.country.data.image_path;
                         object.player_id = item.player_id;
                         object.position = setPlayerPosition(playerData.data.data.position_id);
                         object.name = playerData.data.data.display_name;
                         object.age = setAge(playerData.data.data.birthdate);
                         object.appearences = item.appearences === null ? 0 : item.appearences;
                         object.goals = item.goals === null ? 0 : item.goals;
                         object.yellow_cards = item.yellowcards === null ? 0 : item.yellowcards;
                         object.red_cards = item.redcards === null ? 0 : item.redcards;
                         return object;
                    }));
               const order = ["Goleiro", "Zagueiro", "Meio-campo", "Atacante"];
               const finalSquadArray = squadArray.sort((a, b) => {
                    return (
                         order.indexOf(a.position) - order.indexOf(b.position)
                    );
               });
               setSquad(finalSquadArray);
               const finalLatestMatchesArray = await Promise.all(
                    response.data.data.latest?.data?.slice(0, 10).map(async (item) => {
                         const object = {};
                         const rivalTeamId = item.localteam_id === Number(id) ? item.visitorteam_id : item.localteam_id;
                         const rivalTeamData = await axios
                              .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/teams/${rivalTeamId}?
                              api_token=${process.env.REACT_APP_API_TOKEN}`);
                         object.match_id = item.id;
                         object.status = item.time.status;
                         object.local_name = response.data.data.name;
                         object.local_flag = response.data.data.logo_path;
                         object.rival_name = rivalTeamData.data.data.name;
                         object.rival_flag = rivalTeamData.data.data.logo_path;
                         object.local_score = item.localteam_id === Number(id) ? item.scores.localteam_score : item.scores.visitorteam_score;
                         object.rival_score = item.localteam_id === Number(id) ? item.scores.visitorteam_score : item.scores.localteam_score;
                         object.date = `${item.time?.starting_at?.date?.split('-')[2]}/${item.time?.starting_at?.date?.split('-')[1]}/${item.time?.starting_at?.date?.split('-')[0]}`;
                         object.result = object.local_score < object.rival_score ? 'D' : object.local_score > object.rival_score ? 'V' : 'E';
                         return object;
                    }));
               setLatestMatches(finalLatestMatchesArray);
               const finalUpcomingMatchesArray = await Promise.all(
                    response.data.data.upcoming?.data?.slice(0, 10).map(async (item) => {
                         const object = {};
                         const rivalTeamId = item.localteam_id === Number(id) ? item.visitorteam_id : item.localteam_id;
                         const rivalTeamData = await axios
                              .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/teams/${rivalTeamId}?
                              api_token=${process.env.REACT_APP_API_TOKEN}`);
                         object.match_id = item.id;
                         object.status = item.time.status;
                         object.local_name = response.data.data.name;
                         object.local_flag = response.data.data.logo_path;
                         object.rival_name = rivalTeamData.data.data.name;
                         object.rival_flag = rivalTeamData.data.data.logo_path;
                         object.date = `${item.time?.starting_at?.date?.split('-')[2]}/${item.time?.starting_at?.date?.split('-')[1]}/${item.time?.starting_at?.date?.split('-')[0]}`;
                         return object;
                    }));
               setUpcomingMatches(finalUpcomingMatchesArray);
               const finalTransfersArray = await Promise.all(
                    response.data.data.transfers?.data?.sort((a, b) => {
                         a = a.date.split('-');
                         b = b.date.split('-');
                         return b[0] - a[0] || b[1] - a[1] || b[2] - a[2];
                    }).filter((item) => {
                         if (item.type === 'OUT') return false;
                         return true;
                    }).slice(0, 10).map(async (item) => {
                         const object = {};
                         const playerId = item.player_id;
                         const toTeamId = item.to_team_id;
                         const playerData = await axios
                              .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/players/${playerId}?
                              api_token=${process.env.REACT_APP_API_TOKEN}`);
                         const toTeamData = await axios
                              .get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/teams/${toTeamId}?
                              api_token=${process.env.REACT_APP_API_TOKEN}`);
                         object.transfer = setTransferStatus(item.type, item.transfer);
                         object.type = item.type;
                         object.date = `${item.date.split('-')[2]}/${item.date.split('-')[1]}/${item.date.split('-')[0]}`;
                         object.player_id = playerData.data.data.player_id;
                         object.player_name = playerData.data.data.display_name;
                         object.toTeamId = toTeamId;
                         object.toTeamName = toTeamData.data.data.name;
                         object.toTeamFlag = toTeamData.data.data.logo_path;
                         return object;
                    })
               );
               setTransfers(finalTransfersArray);
          };
          if (id !== 'null') getteamData();
     }, [id]);

     if (id === 'null') return (
          <NoDataFullPageContainer>
               <NoDataMainDiv>
                    <NoDataSpan>
                         <ErrorIcon
                              sx={{
                                   fontSize: 22,
                                   color: '#2178BD'
                              }}
                         />
                         Não há dados para o time selecionado.
                    </NoDataSpan>
               </NoDataMainDiv>
          </NoDataFullPageContainer>
     );

     if (team.length === 0) return null;

     return (
          <Container>
               <TeamHeader
                    team={team}
               />
               <TeamData
                    team={team}
                    squad={squad}
                    transfers={transfers}
                    latestMatches={latestMatches}
                    upcomingMatches={upcomingMatches}
               />
          </Container>
     )
}

export default TeamDetailsSection