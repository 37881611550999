import React, { useState } from 'react';

// Components imported in the screen
import Navigation from './Navigation/Navigation';
import TeamDetails from './TeamDetails/TeamDetails';
import SquadDetails from './SquadDetails/SquadDetails';
import LastMatches from './LastMatches/LastMatches';
import NextMatches from './NextMatches/NextMatches';
// import TeamTransfers from './TeamTransfers/TeamTransfers';

// Functions
import {
     setAge
} from '../../../functions';

const TeamData = (props) => {
     const team = props.team;
     const coachAge = setAge(team?.coach?.data?.birthdate);
     const squad = props.squad;
     // const transfers = props.transfers;
     const latestMatches = props.latestMatches;
     const upcomingMatches = props.upcomingMatches;

     const [teamOption, setTeamOption] = useState('details');

     const handleTeamOption = (value) => {
          setTeamOption(value);
     }

     return (
          <>
               <Navigation
                    option={teamOption}
                    handleTeamOption={handleTeamOption}
               />
               {
                    teamOption === 'details' &&
                    <TeamDetails
                         team={team}
                         coachAge={coachAge}
                    />
               }
               {
                    teamOption === 'squad' &&
                    <SquadDetails
                         squad={squad}
                    />
               }
               {/* {
                    teamOption === 'transfers' &&
                    <TeamTransfers
                         transfers={transfers}
                    />
               } */}
               {
                    teamOption === 'last' &&
                    <LastMatches
                         team={team}
                         latestMatches={latestMatches}
                    />
               }
               {
                    teamOption === 'next' &&
                    <NextMatches
                         team={team}
                         upcomingMatches={upcomingMatches}
                    />
               }
          </>
     );
};

export default TeamData;