import React from 'react';

// Components imported in the screen
import GameHeader from '../../../ResultsSection/GameHeader/GameHeader';

// Styled components
import {
     BoldFont,
     LineupContainer,
     LineupTeamFlag,
     MatchDetailsList,
     StatisticsListData,
     StatisticsListItem
} from '../../../../styles';

const Statistics = (props) => {
     const stats = props.stats;
     const localteamflag = props.localteamflag;
     const visitorteamflag = props.visitorteamflag;

     return (
          <>
               <GameHeader
                    event='Estatísticas'
               />
               <LineupContainer>
                    <MatchDetailsList>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].possessiontime ? `${stats?.[0].possessiontime}%` : 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Posse de bola</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].possessiontime ? `${stats?.[1].possessiontime}%` : 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].shots?.total ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Tentativas de gol</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].shots?.total ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].shots?.ongoal ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Chutes no gol</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].shots?.ongoal ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].shots?.offgoal ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Chutes fora do gol</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].shots?.offgoal ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].shots?.blocked ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Chutes bloqueados</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].shots?.blocked ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].free_kick ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Tiros livres</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].free_kick ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].corners ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Escanteios</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].corners ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].throw_in ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Laterais</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].throw_in ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].saves ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Defesas do goleiro</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].saves ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].fouls ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Faltas</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].fouls ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].yellowcards ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Cartões amarelos</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].yellowcards ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].redcards ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Cartões vermelhos</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].redcards ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].passes?.total ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Total de passes</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].passes?.total ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].passes?.accurate ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Passes completos</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].passes?.accurate ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].tackles ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Roubos de bola</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].tackles ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].attacks?.attacks ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Ataques</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].attacks?.attacks ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                         <StatisticsListItem>
                              <StatisticsListData>
                                   <LineupTeamFlag src={localteamflag} alt='' />
                                   <span>{stats?.[0].attacks?.dangerous_attacks ?? 'Indisponível'}</span>
                              </StatisticsListData>
                              <BoldFont>Ataques perigosos</BoldFont>
                              <StatisticsListData>
                                   <span>{stats?.[1].attacks?.dangerous_attacks ?? 'Indisponível'}</span>
                                   <LineupTeamFlag src={visitorteamflag} alt='' />
                              </StatisticsListData>
                         </StatisticsListItem>
                    </MatchDetailsList>
               </LineupContainer>
          </>
     );
};

export default Statistics;