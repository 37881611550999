import React from 'react';

// Components imported in the screen
import PlayerDetails from './PlayerDetails/PlayerDetails';
import PlayerTransfers from './PlayerTransfers/PlayerTransfers';

// Functions
import {
     setAge
} from '../../../functions';

const PlayerData = (props) => {
     const player = props.player;
     const age = setAge(player?.birthdate);
     const transfers = props.transfers;

     return (
          <>
               <PlayerDetails
                    player={player}
                    age={age}
               />
               <PlayerTransfers
                    transfers={transfers}
               />
          </>
     );
};

export default PlayerData;