import React from 'react';
import {
     BrowserRouter,
     Route,
     Routes
} from 'react-router-dom';

// Pages imported to the screen
import HomePage from './pages/HomePage';
import MatchDetails from './pages/MatchDetails';
import PlayerDetails from './pages/PlayerDetails';
import TeamDetails from './pages/TeamDetails';
import TeamLastMatches from './pages/TeamLastMatches';
import TeamNextMatches from './pages/TeamNextMatches';
// import TeamTransfers from './pages/TeamTransfers';

function App() {
     return (
          <BrowserRouter>
               <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/match/:id' element={<MatchDetails />} />
                    <Route path='/player/:id' element={<PlayerDetails />} />
                    <Route path='/team/:id' element={<TeamDetails />} />
                    <Route path='/team/:id/lastmatches' element={<TeamLastMatches />} />
                    <Route path='/team/:id/nextmatches' element={<TeamNextMatches />} />
                    {/* <Route path='/team/:id/transfers' element={<TeamTransfers />} /> */}
               </Routes>
          </BrowserRouter>
     );
};

export default App;