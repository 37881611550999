import React from 'react';

import {
     NavigationBarContainer,
     SearchBarContainer,
     SearchBarDateButton,
     SearchBarInput,
     SearchBarLeftSide
} from '../../styles';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const SearchOptions = (props) => {
     const date = props.date;

     return (
          <NavigationBarContainer>
               <SearchBarContainer>
                    <SearchBarLeftSide>
                         <SearchBarDateButton onClick={props.minusDate}>
                              <ArrowBackIosNewIcon
                                   sx={{
                                        fontSize: 18,
                                   }}
                              />
                         </SearchBarDateButton>
                         <SearchBarInput
                              type='date'
                              value={date}
                              onChange={props.handleDateSelection}
                         />
                         <SearchBarDateButton onClick={props.plusDate}>
                              <ArrowForwardIosIcon
                                   sx={{
                                        fontSize: 18,
                                   }}
                              />
                         </SearchBarDateButton>
                    </SearchBarLeftSide>
                    <SearchBarInput
                         placeholder='Pesquisar jogos...'
                         onChange={props.handleInputEntry}
                         type='text'
                    />
               </SearchBarContainer>
          </NavigationBarContainer>
     );
};

export default SearchOptions;