import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactLoading from 'react-loading';

// Components imported in the screen
import GameData from '../GameData/GameData';
import GameHeader from '../GameHeader/GameHeader';

// Functions
import { setGameStatus } from '../../../functions';

// Styled components
import {
     LoadingContainer,
     NoDataFullPageContainer,
     NoDataMainDiv,
     NoDataSpan
} from '../../../styles';

// Icons
import ErrorIcon from '@mui/icons-material/Error';

const ScheduledGames = (props) => {
     const date = props.date;
     const inputText = props.inputText;

     const [allGames, setAllGames] = useState([]);
     const [allLeagues, setAllLeagues] = useState([]);
     const [loading, setLoading] = useState(false);

     useEffect(() => {
          setLoading(true);
          async function getAllGames() {
               const scheduledToday = await axios.get(`${process.env.REACT_APP_SOCCER_API_BASE_URL}/fixtures/date/${date}?
                    api_token=${process.env.REACT_APP_API_TOKEN}
                    &include=localTeam,visitorTeam,league
                    &tz=America/Fortaleza`);

               const allGames = scheduledToday.data.data.reduce((redutor, value) => {
                    if (!redutor.some(element => element.id === value.id)) {
                         redutor.push(value);
                    };
                    return redutor;
               }, []);

               const filteredScheduledGames = allGames.filter(element => element.time.status === 'NS');

               const scheduledLeagues = filteredScheduledGames.reduce((redutor, value) => {
                    if (!redutor.some(element => element.name === value.league.data.name)) {
                         redutor.push({
                              name: value.league.data.name,
                              flag: value.league.data.logo_path
                         });
                    };
                    return redutor;
               }, []);

               scheduledLeagues.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

               setAllGames(filteredScheduledGames);
               setAllLeagues(scheduledLeagues);
               setLoading(false);
          };
          getAllGames();
     }, [date]);

     if (allGames.length === 0) { return null; };
     if (allLeagues.length === 0) { return null; };

     const filteredGames = allGames.filter((game) => {
          if (inputText.length === 0) {
               return game;
          } else {
               return game.localTeam.data.name.toLowerCase().includes(inputText) || game.visitorTeam.data.name.toLowerCase().includes(inputText);
          };
     });

     return (
          <>
               {
                    loading ?
                         <LoadingContainer>
                              <ReactLoading
                                   type='spin'
                                   color='#2178BD'
                                   height={40}
                                   width={40}
                              />
                         </LoadingContainer> :
                         inputText.length > 0 ?
                              filteredGames.length === 0 ?
                                   <NoDataFullPageContainer>
                                        <NoDataMainDiv>
                                             <NoDataSpan>
                                                  <ErrorIcon
                                                       sx={{
                                                            fontSize: 22,
                                                            color: '#2178BD'
                                                       }}
                                                  />
                                                  Nenhum jogo encontrado.
                                             </NoDataSpan>
                                        </NoDataMainDiv>
                                   </NoDataFullPageContainer> :
                                   filteredGames?.map((element, index) => {
                                        return <div key={index}>
                                             <GameHeader
                                                  event={element.league.data.name}
                                                  flag={element.league.data.logo_path}
                                             />
                                             {
                                                  filteredGames.map((subelement, index) => {
                                                       const status = setGameStatus(subelement.time.status);
                                                       return subelement.league.data.name === element.league.data.name ?
                                                            <GameData
                                                                 key={index}
                                                                 id={subelement.id}
                                                                 teamA={subelement.localTeam.data.name}
                                                                 teamB={subelement.visitorTeam.data.name}
                                                                 status={status}
                                                                 time={status === 'Ao vivo' ? `${subelement.time.minute}`
                                                                      : status === 'Finalizado' ? ''
                                                                           : status === 'Finalizado na prorrogação' ? ''
                                                                                : status === 'Finalizado nos pênaltis' ? ''
                                                                                     : status === 'Intervalo' ? ''
                                                                                          : subelement.time.starting_at.time.slice(0, 5)}
                                                                 added={status === 'Ao vivo' ? subelement.time.injury_time : ''}
                                                                 logoTeamA={subelement.localTeam.data.logo_path}
                                                                 logoTeamB={subelement.visitorTeam.data.logo_path}
                                                                 scoreA={subelement.scores.localteam_score}
                                                                 scoreB={subelement.scores.visitorteam_score}
                                                                 scorePenaltyA={subelement.scores.localteam_pen_score === null ? '0' : subelement.scores.localteam_pen_score}
                                                                 scorePenaltyB={subelement.scores.visitorteam_pen_score === null ? '0' : subelement.scores.visitorteam_pen_score}
                                                            /> : ''
                                                  })
                                             }
                                        </div>
                                   }) :
                              allLeagues?.map((element, index) => {
                                   return <div key={index}>
                                        <GameHeader
                                             event={element.name}
                                             flag={element.flag}
                                        />
                                        {
                                             allGames?.map((subelement, index) => {
                                                  const status = setGameStatus(subelement.time.status);
                                                  return subelement.league.data.name === element.name ?
                                                       <GameData
                                                            key={index}
                                                            id={subelement.id}
                                                            teamA={subelement.localTeam.data.name}
                                                            teamB={subelement.visitorTeam.data.name}
                                                            status={status}
                                                            time={status === 'Ao vivo' ? `${subelement.time.minute}`
                                                                 : status === 'Finalizado' ? ''
                                                                      : status === 'Finalizado na prorrogação' ? ''
                                                                           : status === 'Finalizado nos pênaltis' ? ''
                                                                                : status === 'Intervalo' ? ''
                                                                                     : subelement.time.starting_at.time.slice(0, 5)}
                                                            added={status === 'Ao vivo' ? subelement.time.injury_time : ''}
                                                            logoTeamA={subelement.localTeam.data.logo_path}
                                                            logoTeamB={subelement.visitorTeam.data.logo_path}
                                                            scoreA={subelement.scores.localteam_score}
                                                            scoreB={subelement.scores.visitorteam_score}
                                                            scorePenaltyA={subelement.scores.localteam_pen_score === null ? '0' : subelement.scores.localteam_pen_score}
                                                            scorePenaltyB={subelement.scores.visitorteam_pen_score === null ? '0' : subelement.scores.visitorteam_pen_score}
                                                       /> : ''
                                             })
                                        }
                                   </div>
                              })
               }
          </>
     );
}

export default ScheduledGames