import React from 'react';

// Components imported in the screen
import GameHeader from '../../../ResultsSection/GameHeader/GameHeader';

// Styled components
import {
     LineupContainer,
     MatchDetailsIconAndTitle,
     MatchDetailsList,
     MatchDetailsListItem,
     SpaceBetween
} from '../../../../styles';

// Icons
import StadiumIcon from '@mui/icons-material/Stadium';
import SportsIcon from '@mui/icons-material/Sports';
import GroupsIcon from '@mui/icons-material/Groups';

const MatchDetails = (props) => {
     const referee = props.referee;
     const venue = props.venue;
     const attendance = props.attendance;

     return (
          <>
               <GameHeader
                    event='Detalhes da partida'
               />
               <LineupContainer>
                    <MatchDetailsList>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        <StadiumIcon sx={{ fontSize: 22, color: '#2178BD' }} />
                                        Estádio
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {venue?.name || 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        <SportsIcon sx={{ fontSize: 22, color: '#2178BD' }} />
                                        Juiz
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {referee?.fullname || 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                         <MatchDetailsListItem>
                              <SpaceBetween>
                                   <MatchDetailsIconAndTitle>
                                        <GroupsIcon sx={{ fontSize: 22, color: '#2178BD' }} />
                                        Público presente
                                   </MatchDetailsIconAndTitle>
                                   <span>
                                        {attendance || 'Indisponível'}
                                   </span>
                              </SpaceBetween>
                         </MatchDetailsListItem>
                    </MatchDetailsList>
               </LineupContainer>
          </>
     );
};

export default MatchDetails;