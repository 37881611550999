import React from 'react';

// Styled components
import {
     GameHeaderContainer,
     GameHeaderFlag,
     GameHeaderMainDiv,
     GameHeaderEvent,
     GameHeaderScore,
     GameHeaderRightSide
} from '../../../styles';

const GameHeader = (props) => {
     return (
          <GameHeaderContainer>
               <GameHeaderMainDiv>
                    <GameHeaderRightSide>
                         {
                              props.flag &&
                              <GameHeaderFlag src={props.flag} alt='flag' />
                         }
                         <GameHeaderEvent>
                              {props.event}
                         </GameHeaderEvent>
                    </GameHeaderRightSide>
                    {
                         props.score &&
                         <GameHeaderScore>{props.score}</GameHeaderScore>
                    }
               </GameHeaderMainDiv>
          </GameHeaderContainer>
     );
};

export default GameHeader;