import React from 'react';

// Components imported in the screen
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

// Styled components
import { Container, MainContainer } from '../styles';
import MatchDetailsSection from '../components/MatchDetailsSection/MatchDetailsSection';

const MatchDetails = () => {
     return (
          <Container>
               <Header />
               <MainContainer>
                    <MatchDetailsSection />
               </MainContainer>
               <Footer />
          </Container>
     );
};

export default MatchDetails;