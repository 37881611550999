import React, { useState } from 'react';

// Components imported in the screen
import Header from '../components/Header/Header';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import Footer from '../components/Footer/Footer';
import GameOptions from '../components/GameOptions/GameOptions';
import ResultsSection from '../components/ResultsSection/ResultsSection';
import SearchOptions from '../components/SearchOptions/SearchOptions';

// Styled components
import { Container, MainContainer } from '../styles';

const HomePage = () => {
     const [sport, setSport] = useState('soccer');
     const [option, setOption] = useState('all');
     const [inputText, setInputText] = useState('');
     const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

     const handleNavigationBar = (value) => {
          setSport(value);
     };

     const handleGameOptions = (value) => {
          setOption(value);
     };

     const handleInputEntry = (input) => {
          const text = input.target.value.toLowerCase();
          setInputText(text);
     };

     const handleDateSelection = (input) => {
          const date = input.target.value;
          setDate(date);
     };

     function minusDate() {
          const currentDate = date;
          if (!currentDate) {
               return null;
          };
          const newDate = new Date(currentDate);
          newDate.setDate(newDate.getDate() - 1);
          setDate(newDate.toISOString().slice(0, 10));
     };

     function plusDate() {
          const currentDate = date;
          if (!currentDate) {
               return null;
          };
          const newDate = new Date(currentDate);
          newDate.setDate(newDate.getDate() + 1);
          setDate(newDate.toISOString().slice(0, 10));
     };

     return (
          <Container>
               <Header />
               <MainContainer>
                    <NavigationBar
                         handleNavigationBar={handleNavigationBar}
                         sport={sport}
                    />
                    {
                         sport === 'soccer' &&
                         <>
                              <GameOptions
                                   handleGameOptions={handleGameOptions}
                                   option={option}
                              />
                              <SearchOptions
                                   handleInputEntry={handleInputEntry}
                                   handleDateSelection={handleDateSelection}
                                   date={date}
                                   minusDate={minusDate}
                                   plusDate={plusDate}
                              />
                         </>
                    }
                    <ResultsSection
                         sport={sport}
                         option={option}
                         date={date}
                         inputText={inputText}
                    />
               </MainContainer>
               <Footer />
          </Container>
     );
};

export default HomePage;