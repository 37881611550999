import React from 'react';

// Components imported in the screen
import Lineup from './Lineup/Lineup';
import Event from './Event/Event';
import Statistics from './Statistics/Statistics';
import MatchDetails from './MatchDetails/MatchDetails';

// Styled components
import {
     Container
} from '../../../styles';

const MatchData = (props) => {
     // Events
     const events = props.events;
     const scores = props.scores;
     const status = props.status;

     // Lineup
     const lineup = props.lineup;
     const bench = props.bench;
     const localteamid = props.localteamid;
     const visitorteamid = props.visitorteamid;
     const localteamflag = props.localteamflag;
     const visitorteamflag = props.visitorteamflag;

     // Statistics
     const stats = props.stats;

     // Match details
     const referee = props.referee;
     const venue = props.venue;
     const attendance = props.attendance;

     return (
          <Container>
               {
                    events.length > 0 &&
                    <>
                         <Event
                              events={events}
                              localteamid={localteamid}
                              visitorteamid={visitorteamid}
                              type={1}
                              scores={scores}
                              status={status}
                         />
                         <Event
                              events={events}
                              localteamid={localteamid}
                              visitorteamid={visitorteamid}
                              type={2}
                              scores={scores}
                              status={status}
                         />
                         <Event
                              events={events}
                              localteamid={localteamid}
                              visitorteamid={visitorteamid}
                              type={3}
                              scores={scores}
                              status={status}
                         />
                         <Event
                              events={events}
                              localteamid={localteamid}
                              visitorteamid={visitorteamid}
                              type={4}
                              scores={scores}
                              status={status}
                         />
                    </>
               }
               {
                    lineup.length > 0 &&
                    <Lineup
                         lineup={lineup}
                         bench={bench}
                         localteamid={localteamid}
                         visitorteamid={visitorteamid}
                         localteamflag={localteamflag}
                         visitorteamflag={visitorteamflag}
                    />
               }
               {
                    stats.length > 0 &&
                    <Statistics
                         stats={stats}
                         localteamflag={localteamflag}
                         visitorteamflag={visitorteamflag}
                    />
               }
               <MatchDetails
                    referee={referee}
                    venue={venue}
                    attendance={attendance}
               />
          </Container>
     );
};

export default MatchData;